import { getRandomItem } from "common/array";

import bg1 from "assets/images/placeholder/1.jpg";
import bg2 from "assets/images/placeholder/2.jpg";
import bg3 from "assets/images/placeholder/3.jpg";
import bg4 from "assets/images/placeholder/4.jpg";
import bg5 from "assets/images/placeholder/5.jpg";
import bg6 from "assets/images/placeholder/6.jpg";
import bg7 from "assets/images/placeholder/7.jpg";
import bg8 from "assets/images/placeholder/8.jpg";

export function getBg() {
  return getRandomItem([bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8]);
}
