/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";
import { message } from "antd";
import { Layout, Menu, Breadcrumb } from "antd";
import { Affix, Button } from "antd";
import { Row, Col } from "antd";
import { chunkArray } from "common/array";
import { getInsurancePlans, getBenefitTypes } from "api/api";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { css, jsx } from "@emotion/react";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import logo from "assets/images/logo.png";
import LayoutHeader from "components/Header";
import LayoutFooter from "components/Footer";
import family from "assets/images/family.jpg";
const axios = require("axios");
const { SubMenu } = Menu;
const { Content } = Layout;
export default class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let $this = this;
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Layout css={{ backgroundColor: "#fff" }}>
          <LayoutHeader location={this.props.location} />

          <Content
            className="site-layout"
            css={{
              padding: "0 50px",
              marginTop: 64,
              backgroundColor: "#fafafa",
            }}
          >
            <div
              className="site-layout-background"
              css={{ padding: 24, minHeight: 380 }}
            >
              <div
                className={
                  "container mx-auto px-4 bg-white text-gray-600 rounded-md"
                }
                css={{
                  paddingTop: 40,
                  paddingBottom: 160,
                  paddingLeft: 120,
                  paddingRight: 120,
                  fontSize: 16,
                  marginBottom: 200,
                  borderBottomWidth: 3,
                  borderBottomColor: config.color.primary,
                }}
              >
                <img src={family} className="rounded-md" alt="aboutus" />
                <div
                  className="text-4xl"
                  css={{ color: config.color.primary, paddingTop: 40 }}
                >
                  <strong>Về chúng tôi</strong>
                </div>
                <br />
                <br />
                <br />
                <strong css={{ color: config.color.primary }}>
                  Bảo Hiểm Việt
                </strong>{" "}
                là một công ty cung cấp dịch vụ tư vấn Bảo Hiểm Insuretech (kết
                hợp công nghệ vào Bảo Hiểm) với mục đích mang Bảo Hiểm đúng
                nghĩa đến với Người Việt vì lợi ích của người Việt Giải pháp
                công nghệ Bảo Hiểm Việt sữ giúp khách hàng - những người có nhu
                cầu mua bảo hiểm thực sự hiểu đúng, hiểu đủ về Bảo Hiểm. <br />
                <br /> Trên{" "}
                <strong css={{ color: config.color.primary }}>
                  Bảo Hiểm Việt
                </strong>
                , với nguồn thông tin đa chiều, phân tích khách quan dựa trên
                quyền lợi thật sự của khách hàng, những người tham giam gia/có ý
                định tham gia/có ý định tham gia Bảo hiểm dù với mục đích bảo vệ
                bản thân, gia đình hay tích lũy tài chính hoặc kết hợp bảo vệ
                sức khỏe và tíc lũy tai chính đều sẽ dễ dàng tìm kiếm và lựa
                chọn được sản phẩm phù hợp danh cho riêng mình – theo từng đặc
                điểm cá nhân riêng biệt để đảm bảo quyền lợi tối đa với phí tối
                thiểu và điều kiện có thể đáp ứng được.
                <br />
                <br />
                <strong css={{ color: config.color.primary }}>
                  Tầm nhìn:
                </strong>{" "}
                Trở thành công ty dẫn đầu về chất lượng tư vấn và chăm sóc khách
                hàng, đưa bảo hiểm trở thành sản phẩm dễ hiểu với khách hàng.
                <br />
                <br />
                <strong css={{ color: config.color.primary }}>
                  Sứ mệnh:
                </strong>{" "}
                Đưa sản phẩm Bảo hiểm đến tay khách hàng đúng và đủ quyền lợi,
                để giúp KH được bảo vệ vẹn toàn cuộc sống.
              </div>
            </div>
          </Content>

          <LayoutFooter />
        </Layout>
      </React.Fragment>
    );
  }
}
