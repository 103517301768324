/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";

import { Layout, Menu, Breadcrumb } from "antd";
import { Affix, Button } from "antd";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { css, jsx } from "@emotion/react";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import logo from "assets/images/logo.png";
const axios = require("axios");
const { SubMenu } = Menu;
const { Header, Content, Footer } = Layout;
export default class LayoutHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerHeight: 66,
      activeHeaderShowClass: "shadow-sm",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      let activeHeaderShowClass = "shadow-md";
      let headerHeight = 46;
      if (window.scrollY === 0) {
        activeHeaderShowClass = "shadow-sm";
        headerHeight = 66;
      }
      this.setState({ activeHeaderShowClass });
    });
  }

  getSelectedKeys = () => {
    let keys = [];
    let location = this.props.location;
    if (location.pathname === "/") {
      keys = ["home"];
    } else {
      keys = [location.pathname.replace("/", "")];
    }

    return keys;
  };

  render() {
    return (
      <Header
        className={`${this.state.activeHeaderShowClass} transition duration-150 ease-in-out desktop-header`}
        css={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          backgroundColor: "#fff",
          // height: this.state.headerHeight,
        }}
      >
        <div
          className="logo"
          css={{
            width: 150,
            paddingTop: 10,
            float: "left",
            marginRight: 50,
          }}
          onClick={() => {
            window.location.href = "/#/";
          }}
        >
          <img src={logo} width={150} alt={"Logo"} />
        </div>

        <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={["/"]}
          selectedKeys={this.getSelectedKeys()}
          css={{ float: "right", border: "none" }}
        >
          {config.menu.map((item, index) => {
            if (!item.subs) {
              return (
                <Menu.Item
                  key={item.key}
                  onClick={() => {
                    if (item.href.includes("http")) {
                      window.open(item.href);
                    } else {
                      window.location.href = item.href;
                    }
                  }}
                >
                  <strong>{item.name}</strong>
                </Menu.Item>
              );
            } else {
              return (
                <SubMenu title={<strong>{item.name}</strong>}>
                  {item.subs.map((sub, index) => {
                    return (
                      <Menu.Item
                        key={sub.key}
                        onClick={() => {
                          if (sub.href.includes("http")) {
                            window.open(sub.href);
                          } else {
                            window.location.href = sub.href;
                          }
                        }}
                      >
                        <strong>{sub.name}</strong>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
          })}
        </Menu>
      </Header>
    );
  }
}
