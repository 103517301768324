/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";
import { getFooter } from "api/api";
import { Layout, Menu, Breadcrumb } from "antd";
import { Affix, Button } from "antd";
import { Row, Col, Card } from "antd";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { css, jsx } from "@emotion/react";
import { chunkArray, getRandomItem } from "common/array";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import logo from "assets/images/logo.png";
const axios = require("axios");
const { SubMenu } = Menu;
const { Header, Content, Footer } = Layout;
export default class LayoutFooter extends Component {
  constructor(props) {
    super(props);
    this.state = { footer: [] };
  }

  componentDidMount() {
    let $this = this;

    getFooter().then(function (response) {
      $this.setState({ footer: response }, () => {
        log($this.state);
      });
    });
  }

  render() {
    return (
      <Footer
        css={{
          textAlign: "left",
          backgroundColor: "#2F3639",
          color: "#fff",
        }}
      >
        <Content
          className="site-layout"
          css={{
            padding: "0 50px",
            marginTop: 12,
          }}
        >
          <div className="site-layout-background" css={{ minHeight: 380 }}>
            <div
              className={"container mx-auto "}
              css={{
                paddingTop: 10,
                paddingBottom: 60,
              }}
            >
              <div className="container" css={{ paddingBottom: 50 }}>
                {this.state.footer && this.state.footer.length > 0 ? (
                  <React.Fragment>
                    {chunkArray(this.state.footer, 4).map((chunk, index) => {
                      return (
                        <Row gutter={[16, 16]} key={index}>
                          {chunk.map((section, pindex) => {
                            return (
                              <Col span={6} offset={0} key={pindex}>
                                <h3
                                  className="mb-6 text-lg text-white font-bold"
                                  css={{ color: "#93A4AC" }}
                                >
                                  {section.name}
                                </h3>
                                <ul className="text-white">
                                  {section.links.map((link, index) => {
                                    return (
                                      <li className="mb-3" key={index}>
                                        <a
                                          href={link.url}
                                          css={{ color: "#fff" }}
                                        >
                                          {link.name}
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </Col>
                            );
                          })}
                        </Row>
                      );
                    })}
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
              <span css={{ fontSize: 16, fontWeight: "bold" }}>
                © 2020 Bảo Hiểm Việt
              </span>
              <br />
              <span css={{ fontSize: 14 }}>
                Công ty Cổ phần Dịch vụ Tư vấn Bảo Hiểm Việt - Bảo hiểm cho
                người Việt <br />
                Thành viên của UB Group
                <br />
                Trụ sở: Số 273 Đội Cấn, Phường Ngọc Hà, Quận Ba Đình, Hà Nội{" "}
                <br /> <br />
                Website: www.baohiemviet.com.vn <br />
                Email: dvkh@baohiemviet.com.vn <br />
                Hotline: 024.3232.1999
              </span>
            </div>
          </div>
        </Content>
      </Footer>
    );
  }
}
