/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";

import { Layout, Menu, Breadcrumb } from "antd";
import { Affix, Button } from "antd";
import { Row, Col } from "antd";
import { getWikiBaoHiemVietNews } from "api/api";
import { chunkArray } from "common/array";
import { textTruncate } from "common/string";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { css, jsx } from "@emotion/react";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";

const axios = require("axios");
const { SubMenu } = Menu;
const { Content } = Layout;

export default class HomeNews extends Component {
  constructor(props) {
    super(props);
    this.state = { news: {} };
  }

  componentDidMount() {
    let $this = this;

    getWikiBaoHiemVietNews().then(function (response) {
      if (response.data) {
        $this.setState({ news: response }, () => {
          log($this.state);
        });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    let $this = this;

    getWikiBaoHiemVietNews().then(function (response) {
      if (response.data) {
        $this.setState({ news: response }, () => {
          log($this.state);
        });
      }
    });
  }

  generateNews = () => {
    let news = this.state.news;

    if (news.data && news.data.length > 12) {
      news.data = news.data.slice(0, 12);

      return chunkArray(news.data, 4).map((chunk, index) => {
        return (
          <div
            key={index}
            className="lg:flex md:flex sm:flex xl:justify-around flex-wrap
            md:justify-around sm:justify-around lg:justify-around"
          >
            {chunk.map((val, vindex) => {
              let href = `${config.wiki_domain}/tin-tuc/${val.slug}`;
              let img = `${config.wiki_domain}/upload/media/posts/${val.thumb}-b.jpg`;
              return (
                <div
                  key={vindex}
                  className="xl:mb-0 mb-8 lg:w-1/4 xl:w-1/4 w-11/12 mx-auto
                  xl:mx-0 sm:w-2/5 md:w-5/12 sm:mx-auto xl:pr-4 lg:pl-4 lg:pr-0"
                >
                  <a target={"_blank"} rel="noreferrer" href={href}>
                    <div className="h-48 bg-cover rounded">
                      <img
                        src={img}
                        alt={"Cover"}
                        className="h-full w-full object-cover overflow-hidden rounded shadow"
                      />
                    </div>
                    <div className="px-4 pt-4 pb-5 bg-white ">
                      <p className="text-center text-xl font-semibold pb-4 text-gray-700">
                        {textTruncate(val.title, 64)}
                      </p>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        );
      });
    }

    return "";
  };

  render() {
    return (
      <React.Fragment>
        <Content
          className="site-layout"
          css={{
            padding: "0 50px",
            marginTop: 12,
            backgroundColor: "#fff",
          }}
        >
          <div
            className="site-layout-background"
            css={{ padding: 24, minHeight: 380 }}
          >
            <div
              className={"container mx-auto px-4 "}
              css={{
                paddingTop: 10,
                paddingBottom: 60,
              }}
            >
              <div
                className={"text-3xl font-bold text-gray-700"}
                css={{
                  paddingBottom: 50,
                }}
              >
                Thông tin hữu ích
              </div>

              {this.state.news.data && this.state.news.data.length > 12
                ? this.generateNews()
                : ""}
            </div>
          </div>
        </Content>
      </React.Fragment>
    );
  }
}
