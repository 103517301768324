/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";

import { Layout, Menu, Breadcrumb } from "antd";
import { Affix, Button } from "antd";
import { Row, Col } from "antd";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { css, jsx } from "@emotion/react";
import _ from "lodash";

import { getFAQs } from "api/api";
import { chunkArray } from "common/array";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import config from "config.js";
import log from "common/log.js";

const axios = require("axios");
const { SubMenu } = Menu;
const { Content } = Layout;

function FAQItem(props) {
  return (
    <div css={{ paddingBottom: 30 }}>
      <div css={{ fontSize: 18, marginBottom: 10 }}>
        <strong>{props.question}</strong>
      </div>
      {props.answer()}
    </div>
  );
}

export default class HomeFAQ extends Component {
  constructor(props) {
    super(props);
    this.state = { faqs: [] };
  }

  componentDidMount() {
    let $this = this;

    getFAQs().then(function (response) {
      $this.setState({ faqs: response }, () => {
        log($this.state);
      });
    });
  }

  render() {
    return (
      <React.Fragment>
        <Content
          className="site-layout"
          css={{
            padding: "0 50px",
            marginTop: 12,
            backgroundColor: "#fafafa",
          }}
        >
          <div
            className="site-layout-background"
            css={{ padding: 24, minHeight: 380 }}
          >
            <div
              className={"container mx-auto px-4 "}
              css={{
                paddingTop: 10,
                paddingBottom: 60,
              }}
            >
              <div
                className={"text-3xl font-bold text-gray-700"}
                css={{
                  paddingBottom: 50,
                }}
              >
                Những câu hỏi thường gặp
              </div>
              <div css={{ fontSize: 16 }} className={"text-gray-700"}>
                <Row>
                  <Col span={11} offset={0}>
                    {this.state.faqs.length > 0 ? (
                      <React.Fragment>
                        {this.state.faqs.map((val, index) => {
                          if (index % 2 !== 0) {
                            return (
                              <FAQItem
                                key={index}
                                question={val.question}
                                answer={() => {
                                  return (
                                    <React.Fragment>
                                      {ReactHtmlParser(val.answer)}
                                    </React.Fragment>
                                  );
                                }}
                              />
                            );
                          }

                          return "";
                        })}
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col span={11} offset={1}>
                    {this.state.faqs.length > 0 ? (
                      <React.Fragment>
                        {this.state.faqs.map((val, index) => {
                          if (index % 2 === 0) {
                            return (
                              <FAQItem
                                key={index}
                                question={val.question}
                                answer={() => {
                                  return (
                                    <React.Fragment>
                                      {ReactHtmlParser(val.answer)}
                                    </React.Fragment>
                                  );
                                }}
                              />
                            );
                          }

                          return "";
                        })}
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>{" "}
              </div>
            </div>
          </div>
        </Content>
      </React.Fragment>
    );
  }
}
