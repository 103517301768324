/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { message } from "antd";
import { Layout, Menu, Breadcrumb } from "antd";
import { Affix, Button } from "antd";
import { Row, Col, Card } from "antd";
import { Steps } from "antd";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
  HeartOutlined,
  SafetyOutlined,
  FileSearchOutlined,
  FolderAddOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import { chunkArray, getRandomItem } from "common/array";
import { checkNested, makeMobileNumberVN } from "common/util";
import { textTruncate } from "common/string";
import { getHealthInsuranceProduct, postCustomer } from "api/api";
import { validEmail, validUrl, valideMobileVN } from "common/validator.js";
import {
  LoadingBox,
  shortDescription,
  shortDescriptionSingleBr,
  scrollToRef,
} from "components/common";
import { getBg } from "common/background";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import logo from "assets/images/logo.png";
import LayoutHeader from "components/Header";
import LayoutFooter from "components/Footer";

const slugify = require("slugify");
const queryString = require("query-string");
const axios = require("axios");
const { Step } = Steps;
const { SubMenu } = Menu;
const { Content } = Layout;
const { Panel } = Collapse;

function TopReason(props) {
  return (
    <React.Fragment>
      <div css={{ float: "left" }}>
        <SafetyCertificateOutlined
          style={{
            fontSize: "64px",
            color: config.color.primary,
          }}
        />
      </div>
      <div css={{ marginLeft: 80 }}>
        <div
          css={{
            fontWeight: "bold",
            fontSize: 14,
            color: config.color.primary,
            paddingBottom: 6,
          }}
        >
          {props.title}
        </div>
        {props.description}
      </div>
    </React.Fragment>
  );
}

export default class HealthProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      email: "",
    };

    this.reasonRef = React.createRef();
    this.infoRef = React.createRef();
    this.benefitRef = React.createRef();
    this.addonRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let $this = this;
    let productId = this.props.match.params.id;
    getHealthInsuranceProduct(productId).then((response) => {
      $this.setState({ product: response }, () => {
        log($this.state);
      });
    });
  }

  submitCustomer = () => {
    let data = {
      customer: {},
      consultation: {
        health_insurance_product: this.props.match.params.id,
      },
    };

    let $this = this;
    let hasAlerted = false;
    let ok = true;
    let formData = {
      full_name: $this.state.name,
      phone: $this.state.phone,
      email: $this.state.email,
    };

    // Check name
    if (formData.name === "") {
      ok = false;
    }

    // Check mail
    if (formData.email === "") {
      ok = false;
    } else {
      if (validEmail(formData.email) === false) {
        if (!hasAlerted) {
          message.info("Email của bạn không đúng định dạng!");
          hasAlerted = true;
        }

        ok = false;
      }
    }

    // Check phone
    if (formData.phone === "") {
      ok = false;
    } else {
      let is_mobile_ok = false;
      formData.phone = makeMobileNumberVN(formData.phone);
      if (formData.phone !== "") {
        if (valideMobileVN(formData.phone) === false) {
          if (!hasAlerted) {
            hasAlerted = true;
            message.info("Số điện thoại của bạn không đúng định dạng!");
          }
        } else {
          is_mobile_ok = true;
        }
      } else {
        if (!hasAlerted) {
          hasAlerted = true;
          message.info("Bạn chưa điền số điện thoại!");
        }
      }

      if (is_mobile_ok === false) {
        ok = false;
      }
    }

    if (ok === true) {
      data["customer"] = formData;
      postCustomer(data);
    } else {
      if (!hasAlerted) {
        message.info("Mời bạn nhập đầy đủ thông tin trên form.");
      }
    }
  };

  render() {
    let rowTableStyle = {
      paddingBottom: 12,
      marginBottom: 12,
      borderBottomWidth: 1,
      borderBottomColor: "#f0f1f2",
    };

    let leftColTableStyle = { fontWeight: "bold" };
    return (
      <React.Fragment>
        <Layout css={{ backgroundColor: "#fff" }}>
          <LayoutHeader location={this.props.location} />

          <div
            css={{
              marginTop: 64,
            }}
          >
            <div
              className="bg-gray-100 dark:bg-gray-800 py-4"
              css={{ backgroundColor: config.color.primary }}
            >
              <div
                className="container px-6 mx-auto flex flex-col md:flex-row items-start md:items-center justify-between"
                css={{ padding: "0 50px" }}
              >
                <div>
                  <div className="" css={{ color: "#fff" }}>
                    {this.state.product ? this.state.product.company.name : ""}
                  </div>
                  <h4
                    className="text-2xl font-bold leading-tight"
                    css={{ color: "#fff" }}
                  >
                    {this.state.product ? this.state.product.name : ""}
                  </h4>
                </div>
                <div className="mt-6 md:mt-0">
                  <button
                    className="rounded-md bg-white transition duration-150 ease-in-out focus:outline-none
                                rounded text-gray-800 border-gray-300 px-8 py-3 text-xl  border-2"
                    css={{
                      marginRight: 0,
                      padding: "6px 20px",
                      width: 160,
                      borderColor: "#f76420",
                      backgroundColor: "#fff",
                      color: config.color.primary,
                      float: "right",
                    }}
                    onClick={() => {}}
                  >
                    <strong>Tư vấn</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Content
            className="site-layout product-menu"
            css={{
              padding: "0 50px",
            }}
          >
            <Menu mode="horizontal">
              <Menu.Item
                key="lydo"
                icon={
                  <SafetyOutlined
                    style={{ fontSize: "20px", color: config.color.primary }}
                  />
                }
                onClick={() => {
                  scrollToRef(this.reasonRef);
                }}
              >
                Lý do lựa chọn
              </Menu.Item>
              <Menu.Item
                key="thongtin"
                icon={
                  <FileSearchOutlined
                    style={{ fontSize: "20px", color: config.color.primary }}
                  />
                }
                onClick={() => {
                  scrollToRef(this.infoRef);
                }}
              >
                Thông tin chung
              </Menu.Item>
              <Menu.Item
                key="quyenloi"
                icon={
                  <HeartOutlined
                    style={{ fontSize: "20px", color: config.color.primary }}
                  />
                }
                onClick={() => {
                  scrollToRef(this.benefitRef);
                }}
              >
                Quyền lơi bảo hiểm
              </Menu.Item>
              {/* <Menu.Item
                key="spbotro"
                icon={
                  <FolderAddOutlined
                    style={{ fontSize: "20px", color: config.color.primary }}
                  />
                }
                onClick={() => {
                  scrollToRef(this.addonRef);
                }}
              >
                Sản phẩm bảo hiểm bổ trợ
              </Menu.Item> */}
            </Menu>
          </Content>
          <Content
            className="site-layout"
            css={{
              padding: "0 50px",
              backgroundColor: "#fafafa",
              paddingBottom: 40,
            }}
          >
            <Row>
              <Col span={12}>
                <div
                  className="text-2xl font-bold"
                  css={{
                    paddingTop: 40,
                  }}
                >
                  {this.state.product ? this.state.product.intro_title : ""}
                </div>

                <div
                  css={{ fontSize: 18, paddingTop: 40, paddingBottom: 40 }}
                  className="text-gray-500"
                >
                  {this.state.product
                    ? this.state.product.intro_description
                    : ""}
                </div>
                <div
                  className="text-xl font-bold text-gray-500"
                  css={{
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  Chi tiết sản phẩm
                </div>
                <div
                  css={{
                    fontSize: 14,
                    paddingTop: 0,
                    paddingBottom: 40,
                    width: "90%",
                  }}
                  className="text-gray-500"
                >
                  {this.state.product
                    ? shortDescriptionSingleBr(
                        this.state.product.product_detail
                      )
                    : ""}
                </div>
              </Col>
              <Col span={10} offset={1}>
                <div
                  css={{
                    marginLeft: 40,
                    marginTop: 40,
                    width: "100%",
                    height: 360,

                    backgroundImage: `url(${getBg()})`,
                  }}
                  className="rounded-lg bg-cover"
                />
              </Col>
            </Row>
          </Content>
          <Content
            className="site-layout"
            css={{
              padding: "0 50px",
              // backgroundColor: "#fafafa",
            }}
          >
            <div
              ref={this.reasonRef}
              css={{ paddingTop: 20, paddingBottom: 80 }}
            >
              <div
                className="text-2xl font-bold text-gray-500"
                css={{
                  paddingTop: 40,
                  paddingBottom: 50,
                  textAlign: "center",
                }}
              >
                Lý do lựa chọn
              </div>
              <Row>
                <Col span={6} offset={1}>
                  {this.state.product ? (
                    <TopReason
                      title={this.state.product.reason_1_title}
                      description={this.state.product.reason_1_description}
                    />
                  ) : (
                    ""
                  )}
                </Col>
                <Col span={6} offset={2}>
                  {this.state.product ? (
                    <TopReason
                      title={this.state.product.reason_2_title}
                      description={this.state.product.reason_2_description}
                    />
                  ) : (
                    ""
                  )}
                </Col>
                <Col span={6} offset={2}>
                  {this.state.product ? (
                    <TopReason
                      title={this.state.product.reason_3_title}
                      description={this.state.product.reason_3_description}
                    />
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </div>
          </Content>

          <Content
            className="site-layout"
            css={{
              padding: "0 50px",
              backgroundColor: "#fafafa",
            }}
          >
            <div ref={this.infoRef} css={{ paddingTop: 20, paddingBottom: 80 }}>
              <div
                className="text-2xl font-bold text-gray-500"
                css={{
                  paddingTop: 40,
                  paddingBottom: 50,
                  textAlign: "center",
                }}
              >
                Tài liệu tham khảo
              </div>
              <div
                css={{
                  fontSize: 15,
                  backgroundColor: "#fff",
                  padding: 20,
                  width: 820,
                  margin: "auto",
                  borderColor: "#f0f1f2",
                  borderWidth: 1,
                }}
              >
                <Row css={rowTableStyle}>
                  <Col span={10} css={leftColTableStyle}>
                    Chi tiết hệ thống bảo lãnh:
                  </Col>
                  <Col span={10} offset={2}>
                    {this.state.product &&
                    validUrl(this.state.product.fee_guarantee_system_url) ? (
                      <a
                        href={this.state.product.fee_guarantee_system_url}
                        target="_blank"
                      >
                        Vui lòng tham khảo tại đây
                      </a>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row css={rowTableStyle}>
                  <Col span={10} css={leftColTableStyle}>
                    Hợp đồng mẫu:
                  </Col>
                  <Col span={10} offset={2}>
                    {this.state.product &&
                    validUrl(this.state.product.draft_contact_url) ? (
                      <a
                        href={this.state.product.draft_contact_url}
                        target="_blank"
                      >
                        Vui lòng tham khảo tại đây
                      </a>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row css={rowTableStyle}>
                  <Col span={10} css={leftColTableStyle}>
                    Quy tắc điều khoản:
                  </Col>
                  <Col span={10} offset={2}>
                    {this.state.product &&
                    validUrl(this.state.product.product_tos_url) ? (
                      <a
                        href={this.state.product.product_tos_url}
                        target="_blank"
                      >
                        Vui lòng tham khảo tại đây
                      </a>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row css={rowTableStyle}>
                  <Col span={10} css={leftColTableStyle}>
                    Hướng dẫn hồ sơ yêu cầu bảo hiểm:
                  </Col>
                  <Col span={10} offset={2}>
                    {this.state.product &&
                    validUrl(this.state.product.insurance_claim_guide_url) ? (
                      <a
                        href={this.state.product.insurance_claim_guide_url}
                        target="_blank"
                      >
                        Vui lòng tham khảo tại đây
                      </a>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Content>

          <Content
            className="site-layout"
            css={{
              padding: "0 50px",
              // backgroundColor: "#fafafa",
            }}
          >
            <div
              ref={this.benefitRef}
              css={{ paddingTop: 20, paddingBottom: 80 }}
            >
              <div
                className="text-2xl font-bold text-gray-500"
                css={{
                  paddingTop: 40,
                  paddingBottom: 50,
                  textAlign: "center",
                }}
              >
                Quyền lợi bảo hiểm
              </div>
              <Row css={{ paddingBottom: 40 }}>
                <Col span={4} offset={1}>
                  {this.state.product ? (
                    <TopReason
                      title={"Tổng quyền lợi"}
                      description={this.state.product.total_benefit}
                    />
                  ) : (
                    ""
                  )}
                </Col>
                <Col span={4} offset={2}>
                  {this.state.product ? (
                    <TopReason
                      title={"Quyền lợi nội trú"}
                      description={this.state.product.in_patient_benefit}
                    />
                  ) : (
                    ""
                  )}
                </Col>
                <Col span={4} offset={2}>
                  {this.state.product ? (
                    <TopReason
                      title={"Quyền lợi ngoại trú"}
                      description={this.state.product.out_patient_benefit}
                    />
                  ) : (
                    ""
                  )}
                </Col>
                <Col span={4} offset={2}>
                  {this.state.product ? (
                    <TopReason
                      title={"Quyền lợi nha khoa"}
                      description={this.state.product.dentist_benefit}
                    />
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row>
                <Col span={4} offset={1}>
                  {this.state.product ? (
                    <TopReason
                      title={"Quyền lợi thai sản"}
                      description={this.state.product.pregnancy_benefit}
                    />
                  ) : (
                    ""
                  )}
                </Col>
                <Col span={4} offset={2}>
                  {this.state.product ? (
                    <TopReason
                      title={"Quyền lợi bảo lãnh"}
                      description={this.state.product.fee_guarantee_benefit}
                    />
                  ) : (
                    ""
                  )}
                </Col>
                <Col span={4} offset={2}>
                  {this.state.product ? (
                    <TopReason
                      title={"Quyền lợi hỗ trợ"}
                      description={this.state.product.support_benefit}
                    />
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </div>
          </Content>

          <Content
            className="site-layout"
            css={{
              padding: " 50px",
              backgroundColor: "#fafafa",
            }}
          >
            <div css={{ paddingTop: 20, paddingBottom: 80 }}>
              <div
                className={"shadow-sm rounded-md"}
                css={{
                  backgroundColor: "#fff",
                  marginTop: 50,
                  width: 820,
                  margin: "auto",
                }}
              >
                <div className="xl:w-full border-b border-gray-300 py-6">
                  <div className="flex items-center w-11/12 mx-auto">
                    <div className="text-lg text-gray-800 font-bold">
                      Đăng ký nhận tư vấn bảo hiểm.
                    </div>
                    <div className="ml-2 cursor-pointer text-gray-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={16}
                        height={16}
                      >
                        <path
                          className="heroicon-ui"
                          d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm0-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="w-11/12 mx-auto">
                  <div className="container mx-auto">
                    <div
                      className="my-6 mx-auto xl:w-full xl:mx-0"
                      style={{ marginTop: 20, marginBottom: 30 }}
                    >
                      <div className="xl:w-full lg:w-full md:w-full flex flex-col mb-4">
                        <label
                          htmlFor="email2"
                          className="pb-2 text-sm font-bold text-gray-800"
                        >
                          Số điện thoại
                        </label>
                        <div className="relative">
                          <div className="absolute text-gray-600 flex items-center px-4 border-r h-full">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                              <path d="M15 7a2 2 0 0 1 2 2" />
                              <path d="M15 3a6 6 0 0 1 6 6" />
                            </svg>
                          </div>
                          <input
                            value={this.state.phone}
                            onChange={(e) => {
                              this.setState({ phone: e.target.value });
                            }}
                            id="phone"
                            name="phone"
                            required
                            className="w-full text-gray-800 focus:outline-none
                        focus:border focus:border-indigo-700 font-normal py-2 flex
                        items-center pl-16 text-sm rounded border"
                            placeholder="Mời bạn nhập SĐT"
                          />
                        </div>
                      </div>

                      <div className="xl:w-full lg:w-full md:w-full flex flex-col mb-4">
                        <label
                          htmlFor="FirstName"
                          className="pb-2 text-sm font-bold text-gray-800"
                        >
                          Họ tên
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          required
                          id="FirstName"
                          className="border border-gray-300 pl-3 py-2 shadow-sm rounded text-sm
                       focus:outline-none focus:border-indigo-700 text-gray-800"
                          placeholder="Mời bạn nhập họ tên"
                          value={this.state.name}
                          onChange={(e) => {
                            this.setState({ name: e.target.value });
                          }}
                        />
                      </div>

                      <div className="xl:w-full lg:w-full md:w-full flex flex-col mb-4">
                        <label
                          htmlFor="email2"
                          className="pb-2 text-sm font-bold text-gray-800"
                        >
                          Email
                        </label>
                        <div className="relative">
                          <div className="absolute text-gray-600 flex items-center px-4 border-r h-full">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-mail"
                              width={20}
                              height={20}
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" />
                              <rect x={3} y={5} width={18} height={14} rx={2} />
                              <polyline points="3 7 12 13 21 7" />
                            </svg>
                          </div>
                          <input
                            value={this.state.email}
                            onChange={(e) => {
                              this.setState({ email: e.target.value });
                            }}
                            id="email2"
                            name="email"
                            required
                            className="w-full text-gray-800 focus:outline-none focus:border
                        focus:border-indigo-700 font-normal py-2 flex items-center pl-16 text-sm rounded border"
                            placeholder="example@gmail.com"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full h-20 bg-gray-100 rounded-b">
                  <button
                    className="rounded-md my-4 bg-white transition duration-150
                  ease-in-out focus:outline-none rounded text-gray-800
                  border-gray-300 px-8 py-2 text-xl leading-6  border-2"
                    css={{
                      float: "right",
                      marginRight: 20,
                      // padding: "10px 50px",
                      borderColor: "#f76420",
                      backgroundColor: "#f76420",
                      color: "#fff",
                    }}
                    onClick={() => {
                      this.submitCustomer();
                    }}
                  >
                    <strong>Gửi tư vấn</strong>
                  </button>
                </div>
              </div>
            </div>
          </Content>

          <LayoutFooter />
        </Layout>
      </React.Fragment>
    );
  }
}
