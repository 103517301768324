export function validEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.toLowerCase());
}

export function validUrl(url) {
  try {
    new URL(url);
  } catch (e) {
    // console.error(e);
    return false;
  }
  return true;
}

export function valideMobileVN(mobile) {
  let vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
  return vnf_regex.test(mobile);
}
