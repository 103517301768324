/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { message } from "antd";
import { Layout, Menu, Breadcrumb } from "antd";
import { Affix, Button } from "antd";
import { Row, Col, Card } from "antd";
import { Steps } from "antd";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { chunkArray } from "common/array";
import { checkNested, makeMobileNumberVN } from "common/util";
import { textTruncate } from "common/string";
import {
  getInsurancePlans,
  getHealthInsuranceProducts,
  getLifeInsuranceProducts,
  getCompanies,
  getBenefitTypes,
  postCustomerPlan,
  putCustomerPlan,
  getCustomerPlan,
  setCustomerPlan,
  getCustomer,
  setCustomer,
  getSessionUUID,
  postCustomer,
} from "api/api";
import { validEmail, valideMobileVN } from "common/validator.js";
import { LoadingBox, shortDescription } from "components/common";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import logo from "assets/images/logo.png";
import LayoutHeader from "components/Header";
import LayoutFooter from "components/Footer";

const slugify = require("slugify");
const queryString = require("query-string");
const axios = require("axios");
const { Step } = Steps;
const { SubMenu } = Menu;
const { Content } = Layout;
const { Panel } = Collapse;

function StepHeader(props) {
  return (
    <Steps current={props.currentStep}>
      <Step title="Chọn nhu cầu Bảo Hiểm" description="" />
      <Step title="Trả lời câu hỏi" description="" />
      <Step title="Lựa chọn SP Bảo Hiểm và So sánh" description="" />
      <Step title="Đăng ký thông tin tư vấn" description="" />
    </Steps>
  );
}

function getSelectedButtonStyle(list, itemId) {
  let style = {};
  if (list.includes(itemId)) {
    style = {
      marginRight: 20,
      borderColor: "#f76420",
      backgroundColor: "#f76420",
      color: "#fff",
    };
  } else {
    style = {
      marginRight: 20,
      borderColor: "#f76420",
    };
  }

  return style;
}

function SimpleRowItem(props) {
  return (
    <div
      className={"text-left"}
      css={{
        float: "left",
        marginRight: "10px",
        color: "#333",
        paddingTop: 6,
        paddingRight: 20,
        paddingLeft: 20,
        position: "relative",
        fontSize: 12,
      }}
      onClick={() => {}}
    >
      {props.item[props.field] ? ReactHtmlParser(props.item[props.field]) : ""}
    </div>
  );
}

function FreeRowItem(props) {
  return (
    <div
      className={"text-left"}
      css={{
        float: "left",
        marginRight: "10px",
        color: "#333",
        paddingTop: 6,
        paddingRight: 20,
        paddingLeft: 20,
        position: "relative",
        fontSize: 12,
      }}
      onClick={() => {}}
    >
      {props.item[props.field]
        ? parseInt(props.item[props.field]).toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND",
          })
        : ""}
    </div>
  );
}

function TableRow(props) {
  return (
    <Row gutter={[16, 16]} css={{ borderBottomWidth: "1px" }}>
      <Col span={4} css={{ borderRightWidth: "1px", borderLeftWidth: "1px" }}>
        <div
          css={{
            width: "100%",
            float: "left",
            marginRight: "10px",
            color: "#333",
            paddingTop: 6,
            paddingRight: 6,
            paddingLeft: 6,
            position: "relative",
          }}
        >
          {props.title}
        </div>
      </Col>
      {props.items.map((val, index) => {
        return (
          <React.Fragment>
            <Col span={5} css={{ borderRightWidth: "1px" }}>
              <props.itemComponent item={val} field={props.field} />
            </Col>
          </React.Fragment>
        );
      })}
    </Row>
  );
}

class LifeInsuranceItem extends Component {
  render() {
    let product = this.props.product;
    let companyLogo = checkNested(product, "company", "logo_url") ? (
      <img src={product.company.logo_url} alt={"logo"} width={92} />
    ) : (
      ""
    );

    let companyName = checkNested(product, "company", "name")
      ? product.company.name
      : "cong-ty";

    let companyNameSlug = slugify(companyName, {
      locale: "vi",
      lower: true,
      strict: true,
    });

    let annualFee = product.annual_fee
      ? parseInt(product.annual_fee).toLocaleString(
          "vi-VN",
          config.currency_locale
        )
      : "";

    let expectedAccumulation = product.expected_accumulation
      ? parseInt(product.expected_accumulation).toLocaleString(
          "vi-VN",
          config.currency_locale
        )
      : "";

    let guaranteedAccumulation = product.guaranteed_accumulation
      ? parseInt(product.guaranteed_accumulation).toLocaleString(
          "vi-VN",
          config.currency_locale
        )
      : "";

    let slug = slugify(product.name, {
      locale: "vi",
      lower: true,
      strict: true,
    });

    let productUrl = `/#/bao-hiem-nhan-tho/${product.id}/${companyNameSlug}/${slug}`;

    return (
      <div
        className={"shadow-sm rounded-md"}
        css={{
          backgroundColor: "#fff",
          marginTop: 10,
          marginBottom: 20,
          padding: 24,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col
            flex={9}
            onClick={() => {
              window.location.href = productUrl;
            }}
          >
            <Row gutter={[16, 16]}>
              <Col col={1}>{companyLogo}</Col>
              <Col col={3}>
                <div className={"text-xl"} css={{ padding: "6px" }}>
                  <strong>{product.name}</strong>
                </div>
              </Col>
            </Row>
          </Col>
          <Col flex={3}>
            <button
              className="rounded-md bg-white transition duration-150
              ease-in-out focus:outline-none
        rounded text-gray-800 border-gray-300
        px-8 py-3 text-xl  border-2"
              css={{
                marginRight: 0,
                padding: "6px 20px",
                width: 160,
                borderColor: "#f76420",
                backgroundColor: "#f76420",
                color: "#fff",
                float: "right",
              }}
              onClick={() => {
                this.props.setPropState({
                  current_step: 4,
                  selected_compare_items: [],
                  selected_life_insurance_product: this.props.product.id,
                });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-arrow-big-right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#fff"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                css={{ float: "left", marginTop: 2 }}
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M4 9h8v-3.586a1 1 0 0 1 1.707 -.707l6.586 6.586a1 1 0 0 1 0 1.414l-6.586 6.586a1 1 0 0 1 -1.707 -.707v-3.586h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1z" />
              </svg>

              <strong>Tư vấn</strong>
            </button>
          </Col>
        </Row>

        <hr />

        <Row gutter={[16, 16]}>
          <Col
            flex={9}
            onClick={() => {
              window.location.href = productUrl;
            }}
          >
            <Row gutter={[16, 16]}>
              <Col flex={3}>
                <div css={{ padding: "12px 0" }}>
                  <div>
                    <strong>Phí thường niên</strong>
                  </div>
                  <div className={"text-2xl"}>{annualFee}</div>
                  <div className={"text-sm text-gray-500"}>/năm</div>
                </div>
              </Col>
              <Col flex={3}>
                <div css={{ padding: "12px 0" }}>
                  <div>
                    <strong>Tích luỹ dự kiến</strong>
                  </div>
                  <div className={"text-2xl"}>{expectedAccumulation}</div>
                  <div className={"text-sm text-gray-500"}></div>
                </div>
              </Col>
              <Col flex={3}>
                <div css={{ padding: "12px 0" }}>
                  <div>
                    <strong>Tích luỹ đảm bảo</strong>
                  </div>
                  <div className={"text-2xl"}>{guaranteedAccumulation}</div>
                  <div className={"text-sm text-gray-500"}></div>
                </div>
              </Col>
            </Row>

            <div>
              <div>
                <strong> ĐẶC ĐIỂM</strong>
              </div>
              <div css={{ color: "#f76420" }}>
                {product.product_characteristics
                  ? product.product_characteristics
                  : ""}
              </div>
              <div css={{ marginTop: 30 }} className="text-gray-700">
                <div>
                  <strong>
                    {product.reason_1_title ? product.reason_1_title : ""}
                  </strong>
                </div>

                <div>
                  <strong>
                    {product.reason_2_title ? product.reason_2_title : ""}
                  </strong>
                </div>

                <div>
                  <strong>
                    {product.reason_3_title ? product.reason_3_title : ""}
                  </strong>
                </div>
              </div>{" "}
            </div>
          </Col>
          <Col flex={3}>
            <button
              className="rounded-md bg-white transition duration-150 ease-in-out
              focus:outline-none rounded text-gray-800 border-gray-300 px-8 py-3 text-xl border-2"
              css={{
                marginTop: 12,
                marginRight: 0,
                width: 160,
                padding: "6px 20px",
                borderColor: "#00b84b",
                backgroundColor: "#00b84b",
                color: "#fff",
                float: "right",
              }}
              onClick={() => {
                this.props.addCompareItem(product);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-square-plus"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#fff"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                css={{ float: "left", marginTop: 2 }}
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <rect x="4" y="4" width="16" height="16" rx="2" />
                <line x1="9" y1="12" x2="15" y2="12" />
                <line x1="12" y1="9" x2="12" y2="15" />
              </svg>

              <strong>So sánh</strong>
            </button>
          </Col>
        </Row>
      </div>
    );
  }
}

class HealthInsuranceItem extends Component {
  render() {
    let product = this.props.product;
    let dentist_care, pregnancy_care;
    if (product != null) {
      if (checkNested(product, "benefits")) {
        let benefits = product.benefits;
        if (benefits.length > 0) {
          for (let i = 0; i <= benefits.length; i++) {
            if (benefits[i]) {
              if (benefits[i].type) {
                if (benefits[i].type === "dentist_care") {
                  dentist_care = benefits[i].insurance_compensation;
                }
                if (benefits[i].type === "pregnancy_care") {
                  pregnancy_care = benefits[i].insurance_compensation;
                }
              }
            }
          }
        }
      }
    }

    let companyLogo = checkNested(
      product,
      "health_insurance_product",
      "company",
      "logo_url"
    ) ? (
      <img
        src={product.health_insurance_product.company.logo_url}
        width={92}
        alt={"logo"}
      />
    ) : (
      ""
    );

    let companyName = checkNested(
      product,
      "health_insurance_product",
      "company",
      "name"
    )
      ? product.health_insurance_product.company.name
      : "cong-ty";

    let companyNameSlug = slugify(companyName, {
      locale: "vi",
      lower: true,
      strict: true,
    });

    let displayPrice = product.display_price
      ? parseInt(product.display_price).toLocaleString(
          "vi-VN",
          config.currency_locale
        )
      : "";

    let slug = slugify(product.name, {
      locale: "vi",
      lower: true,
      strict: true,
    });

    let productUrl = `/#/bao-hiem-suc-khoe/${product.health_insurance_product.id}/${companyNameSlug}/${slug}`;

    return (
      <Col span={8}>
        <Card
          headcss={{ padding: "0 10px" }}
          bodycss={{ padding: "0 10px", paddingTop: 10 }}
          title={
            <Row
              gutter={[16, 16]}
              onClick={() => {
                window.location.href = productUrl;
              }}
            >
              <Col col={1}>{companyLogo}</Col>
              <Col col={2}>
                <div className={"text-xl"} css={{ padding: "2px" }}>
                  <strong>{companyName}</strong>
                </div>
              </Col>
            </Row>
          }
          bordered={false}
          css={{ marginTop: 10, padding: 2 }}
        >
          <div
            className="justify-center text-center"
            css={{ padding: "10px 0" }}
            onClick={() => {
              window.location.href = productUrl;
            }}
          >
            <strong>{product.name}</strong>
            <div className={"text-4xl"}>{displayPrice}</div>
            {/* <div className={"text-sm text-gray-500"}>Khi đến tuổi 55 (*)</div>{" "} */}
          </div>

          <div
            className=""
            css={{ padding: "10px 0" }}
            onClick={() => {
              window.location.href = productUrl;
            }}
          >
            <Row gutter={[10, 10]}>
              <Col flex={1}>Chi phí nằm viện</Col>
              <Col flex={1} className="text-right">
                {product.room_board_per_day ? product.room_board_per_day : ""}
              </Col>
            </Row>

            <Row gutter={[10, 10]}>
              <Col flex={1}>Điều trị ngoại trú</Col>
              <Col flex={1} className="text-right">
                {product.out_patient_care_per_visit
                  ? product.out_patient_care_per_visit
                  : ""}
              </Col>
            </Row>

            <Row gutter={[10, 10]}>
              <Col flex={1}>Nha khoa</Col>
              <Col flex={1} className="text-right">
                {dentist_care}
              </Col>
            </Row>

            <Row gutter={[10, 10]}>
              <Col flex={1}>Thai sản</Col>
              <Col flex={1} className="text-right">
                {pregnancy_care}
              </Col>
            </Row>
          </div>
          <div css={{ paddingTop: 32 }}></div>
          <Row gutter={[10, 10]}>
            <Col flex={1}>
              <button
                className="w-full rounded-md bg-white transition
                          duration-150 ease-in-out focus:outline-none
                          rounded text-gray-800 border-gray-300
                          px-8 py-2 text-md  border-2"
                css={{
                  marginRight: 0,
                  padding: "6px 20px",
                  borderColor: "#f76420",
                  backgroundColor: "#f76420",
                  color: "#fff",
                  float: "left",
                }}
                onClick={() => {
                  this.props.setPropState({
                    current_step: 4,
                    selected_compare_items: [],
                    selected_health_package: product.id,
                  });
                }}
              >
                <strong>Tư vấn</strong>
              </button>
            </Col>
            <Col flex={1}>
              <button
                className="w-full rounded-md bg-white transition duration-150
            ease-in-out focus:outline-none rounded text-gray-800
            border-gray-300 px-8 py-2 text-md  border-2"
                css={{
                  marginRight: 0,
                  padding: "6px 20px",
                  borderColor: "#00b84b",
                  backgroundColor: "#00b84b",
                  color: "#fff",
                  float: "right",
                }}
                onClick={() => {
                  this.props.addCompareItem(this.props.product);
                }}
              >
                <strong>So sánh</strong>
              </button>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  }
}

let defaultState = (plans) => {
  return {
    name: "",
    phone: "",
    email: "",
    is_loading: false,
    selected_insurance_plans: plans,
    current_step: plans.length > 0 ? 2 : 1,
    show_compare_table: false,
    show_life_insurance_products: true,
    companies: [],
    insurance_plans: [],
    selected_compare_items: [],
    accumulate_answers: [],
    no_accumulate_answers: [],
    selected_insurance_tags: [],
    selected_insurance_answers: [],
    all_answers: [],
    all_answer_ids: [],
    insurance_products: [],
    benefit_types: {},
  };
};

export default class Compare extends Component {
  constructor(props) {
    super(props);

    let params = queryString.parse(this.props.location.search);
    let plans = params.plans ? params.plans.split(",").map((x) => +x) : [];
    this.state = defaultState(plans);

    log(this.props.location);
  }

  componentWillReceiveProps(nextProps) {
    window.scrollTo(0, 0);
    let $this = this;
    let params = queryString.parse(nextProps.location.search);
    let plans = params.plans ? params.plans.split(",").map((x) => +x) : [];
    this.setState(defaultState(plans), () => {
      $this.getData();
      log(this.state);
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getData();
    log(this.state);
  }

  getData = () => {
    let $this = this;
    getInsurancePlans().then((response) => {
      $this.setState({ insurance_plans: response }, () => {
        $this.populateAnswers(response);
      });
    });
    getCompanies().then((response) => {
      $this.setState({ companies: response });
    });
    getBenefitTypes().then((response) => {
      $this.setState({ benefit_types: response });
    });
  };

  submitCustomer = () => {
    // let customer = getCustomer();
    let data = {
      customer: {},
      consultation: {
        customer_plan: parseInt(getCustomerPlan()),
      },
    };

    if (this.state.show_life_insurance_products) {
      data["consultation"][
        "life_insurance_product"
      ] = this.state.selected_life_insurance_product;
    } else {
      data["consultation"][
        "health_package"
      ] = this.state.selected_health_package;
    }

    // if (customer != null) {
    //   data["consultation"]["customer"] = parseInt(customer);
    // }

    let $this = this;
    let hasAlerted = false;
    let ok = true;
    let formData = {
      full_name: $this.state.name,
      phone: $this.state.phone,
      email: $this.state.email,
    };

    // Check name
    if (formData.name === "") {
      ok = false;
    }

    // Check mail
    if (formData.email === "") {
      ok = false;
    } else {
      if (validEmail(formData.email) === false) {
        if (!hasAlerted) {
          message.info("Email của bạn không đúng định dạng!");
          hasAlerted = true;
        }

        ok = false;
      }
    }

    // Check phone
    if (formData.phone === "") {
      ok = false;
    } else {
      let is_mobile_ok = false;
      formData.phone = makeMobileNumberVN(formData.phone);
      if (formData.phone !== "") {
        if (valideMobileVN(formData.phone) === false) {
          if (!hasAlerted) {
            hasAlerted = true;
            message.info("Số điện thoại của bạn không đúng định dạng!");
          }
        } else {
          is_mobile_ok = true;
        }
      } else {
        if (!hasAlerted) {
          hasAlerted = true;
          message.info("Bạn chưa điền số điện thoại!");
        }
      }

      if (is_mobile_ok === false) {
        ok = false;
      }
    }

    if (ok === true) {
      data["customer"] = formData;
      postCustomer(data);
    } else {
      if (!hasAlerted) {
        message.info("Mời bạn nhập đầy đủ thông tin trên form.");
      }
    }
  };

  submitCustomerPlan = () => {
    let customerPlan = getCustomerPlan();
    let customer = getCustomer();
    let data = {
      session_id: getSessionUUID(),
      questions: [],
    };

    if (customer != null) {
      data["customer"] = parseInt(customer);
    }

    for (let a = 0; a < this.state.selected_insurance_answers.length; a++) {
      for (let p = 0; p < this.state.insurance_plans.length; p++) {
        let pp = this.state.insurance_plans[p];
        for (let q = 0; q < pp.questions.length; q++) {
          let ppq = this.state.insurance_plans[p].questions[q];
          for (let qa = 0; qa < ppq.answers.length; qa++) {
            if (a === ppq.answers[qa].id) {
              if (data.questions.length === 0) {
                data.questions.push({
                  plan_question: ppq.id,
                  answers: [
                    {
                      plan_question_answer: a,
                    },
                  ],
                });
              } else {
                let exists = false;
                for (let dq = 0; dq < data.questions.length; dq++) {
                  if (data.questions[dq].plan_question === ppq.id) {
                    exists = true;
                  }
                }

                if (exists === true) {
                  for (let dq = 0; dq < data.questions.length; dq++) {
                    if (data.questions[dq].plan_question === ppq.id) {
                      data.questions[dq].answers.push({
                        plan_question_answer: a,
                      });
                    }
                  }
                } else {
                  data.questions.push({
                    plan_question: ppq.id,
                    answers: [
                      {
                        plan_question_answer: a,
                      },
                    ],
                  });
                }
              }
            }
          }
        }
      }
    }

    if (customerPlan != null) {
      putCustomerPlan(customerPlan, data);
    } else {
      postCustomerPlan(data);
    }
  };

  populateAnswers = (response) => {
    let $this = this;
    let plans = response;
    for (var i = 0; i < plans.length; i++) {
      let plan = plans[i];
      plan.tags = [];

      for (var q = 0; q < plan.questions.length; q++) {
        let question = plan.questions[q];
        for (var a = 0; a < question.answers.length; a++) {
          let answer = question.answers[a];
          if (!$this.state.all_answer_ids.includes(answer.id)) {
            let all_answers = $this.state.all_answers;
            let all_answer_ids = $this.state.all_answer_ids;
            all_answers.push(answer);
            all_answer_ids.push(answer.id);
            $this.setState({
              all_answers: all_answers,
              all_answer_ids: all_answer_ids,
            });
          }

          let accumulate_answers = $this.state.accumulate_answers;
          let no_accumulate_answers = $this.state.no_accumulate_answers;

          for (var t = 0; t < answer.insurance_tags.length; t++) {
            let tag = answer.insurance_tags[t];

            plan.tags.pushIfNotExist(tag.id, function (e) {
              return e === tag.id;
            });

            if (tag.id === 7) {
              accumulate_answers.push(answer.id);
            }

            if (tag.id === 8) {
              no_accumulate_answers.push(answer.id);
            }

            $this.setState({
              accumulate_answers: accumulate_answers,
              no_accumulate_answers: no_accumulate_answers,
            });
          }
        }
      }
    }
  };

  handlePlanSelect = (planId) => {
    let selected_insurance_plans = this.state.selected_insurance_plans;

    if (!selected_insurance_plans.includes(planId)) {
      if (config.max_plan - 1 === selected_insurance_plans.length) {
        selected_insurance_plans.push(planId);
        this.setState(
          {
            selected_insurance_plans: selected_insurance_plans,
          },
          () => {
            log(this.state);
          }
        );
      } else {
        message.info(`Vui lòng chỉ chọn ${config.max_plan} dự định`);
      }
    } else {
      selected_insurance_plans.removeIfExists(planId);
      this.setState(
        {
          selected_insurance_plans: selected_insurance_plans,
        },
        () => {
          log(this.state);
        }
      );
    }
  };

  handleAnswerSelect = (answer) => {
    let answer_tags = answer.insurance_tags;
    let selected_insurance_tags = this.state.selected_insurance_tags;
    let selected_insurance_answers = this.state.selected_insurance_answers;

    if (selected_insurance_answers.includes(answer.id)) {
      answer_tags.forEach((element) => {
        selected_insurance_tags.removeIfExists(element);
      });
      selected_insurance_answers.removeIfExists(answer.id);
    } else {
      answer_tags.forEach((element) => {
        selected_insurance_tags.pushIfNotExist(element, function (e) {
          return e === element;
        });
      });
      selected_insurance_answers.push(answer.id);
    }

    selected_insurance_answers.forEach((id) => {
      this.state.all_answers.forEach((_answer) => {
        if (id === _answer.id) {
          _answer.insurance_tags.forEach((t) => {
            selected_insurance_tags.pushIfNotExist(t.id, function (e) {
              return e === t.id;
            });
          });
        }
      });
    });

    this.setState(
      {
        selected_insurance_tags: selected_insurance_tags.filter(function (el) {
          return el !== null && el !== undefined;
        }),
        selected_insurance_answers: selected_insurance_answers,
      },
      () => {
        log(this.state);
        this.submitCustomerPlan();
      }
    );
  };

  getProductsByDiscount = (discount) => {
    this.setState(
      {
        current_step: 3,
        is_loading: true,
        insurance_products: [],
        selected_compare_items: [],
      },
      () => {
        let params = {
          tags: this.state.selected_insurance_tags.toString(),
          discounted: discount,
          ordering: this.state.show_life_insurance_products
            ? "-mscore"
            : "-pscore",
        };

        this.getProducts(params);
      }
    );
  };

  getProductsByCompany = (company) => {
    this.setState(
      {
        current_step: 3,
        is_loading: true,
        insurance_products: [],
        selected_compare_items: [],
      },
      () => {
        let params = {
          tags: this.state.selected_insurance_tags.toString(),
          ordering: this.state.show_life_insurance_products
            ? "-mscore"
            : "-pscore",
          company: company,
        };

        this.getProducts(params);
      }
    );
  };

  getProductsByOrdering = (ordering) => {
    this.setState(
      {
        current_step: 3,
        is_loading: true,
        insurance_products: [],
        selected_compare_items: [],
      },
      () => {
        let params = {
          tags: this.state.selected_insurance_tags.toString(),
          ordering: ordering,
        };
        this.getProducts(params);
      }
    );
  };

  /**
   *
   * @param {*} params
   */
  getProducts = (params) => {
    window.scrollTo(0, 0);
    let $this = this;
    let apiHandler;
    if (this.state.show_life_insurance_products) {
      apiHandler = getLifeInsuranceProducts;
    } else {
      apiHandler = getHealthInsuranceProducts;
    }

    return apiHandler(params).then(function (response) {
      $this.setState(
        {
          insurance_products: response.filter(function (el) {
            // Filter out blank name item
            return el.name !== null && el.name !== undefined && el.name !== "";
          }),
        },
        () => {
          $this.setState({
            current_step: 3,
            is_loading: false,
            selected_compare_items: [],
          });
        }
      );
    });
  };

  getButtonInsuranceAnswerStyle = (answerId) => {
    return getSelectedButtonStyle(
      this.state.selected_insurance_answers,
      answerId
    );
  };

  getButtonInsurancePlanStyle = (planId) => {
    return getSelectedButtonStyle(this.state.selected_insurance_plans, planId);
  };

  generateInsurancePlanButtons = () => {
    let btnInsurancePlanClass = `
    rounded-3xl my-2 bg-white
    transition duration-150
    ease-in-out focus:outline-none
    rounded text-gray-800 border-gray-300
    px-8 py-3 text-sm leading-6  border-2`;

    return this.state.insurance_plans.length > 0 ? (
      <React.Fragment>
        {chunkArray(this.state.insurance_plans, 3).map((arr, index) => {
          return arr.map((val, vindex) => {
            return (
              <React.Fragment>
                <button
                  key={val.id}
                  className={btnInsurancePlanClass}
                  css={this.getButtonInsurancePlanStyle(val.id)}
                  onClick={() => {
                    this.handlePlanSelect(val.id);
                  }}
                >
                  {val.name}
                </button>
                {vindex === 2 ? <br /> : ""}
              </React.Fragment>
            );
          });
        })}
      </React.Fragment>
    ) : (
      ""
    );
  };

  /**
   * generateInsurancePlanQuestions
   */
  generateInsurancePlanQuestions = () => {
    let buttonClass = `rounded-3xl my-2 bg-white transition
    duration-150 ease-in-out focus:outline-none rounded text-gray-800
    border-gray-300 px-8 py-3 text-sm leading-6  border-2`;

    return this.state.insurance_plans.map((plan, index) => {
      if (this.state.selected_insurance_plans.includes(plan.id)) {
        if (plan.questions && plan.questions.length > 0) {
          return plan.questions.map((question, qindex) => {
            return (
              <div css={{ padding: "28px 48px" }} key={question.id}>
                <div className={"text-2xl font-medium text-gray-600"}>
                  {question.name}
                </div>

                <div css={{ paddingTop: 20 }}>
                  {question.answers && question.answers.length > 0
                    ? question.answers.map((answer, aindex) => {
                        return (
                          <button
                            key={answer.id}
                            className={buttonClass}
                            css={this.getButtonInsuranceAnswerStyle(answer.id)}
                            onClick={() => {
                              this.handleAnswerSelect(answer);
                            }}
                          >
                            {answer.name}
                          </button>
                        );
                      })
                    : ""}
                </div>

                <div css={{ paddingTop: 20 }}></div>
              </div>
            );
          });
        }
      }

      return <React.Fragment></React.Fragment>;
    });
  };

  /**
   *
   * @param {*} newState
   */
  setPropState = (newState) => {
    this.setState(newState);
  };

  /**
   *
   * @param {*} item
   */
  addCompareItem = (item) => {
    let items = this.state.selected_compare_items;

    for (var i = 0; i < items.length; i++) {
      if (items[i].name === item.name) {
        message.info(
          `Sản phẩm ${item.name} đã được chọn. Mời bạn chọn tiếp sản phẩm khác để so sánh`
        );
      }
    }

    items.pushIfNotExist(item, function (e) {
      return e.name === item.name;
    });

    if (items.length === 5) {
      items.shift();
    }
    this.setState({ selected_compare_items: items });
  };

  /**
   *
   * @param {*} val
   */
  removeCompareItem = (val) => {
    let selected_compare_items = this.state.selected_compare_items;

    for (var i = 0; i < selected_compare_items.length; i++) {
      if (selected_compare_items[i].name === val.name) {
        selected_compare_items.splice(i, 1);
      }
    }

    this.setState({
      selected_compare_items: selected_compare_items,
    });
  };

  showStep1 = () => {
    window.scrollTo(0, 0);
    return (
      <div className="site-layout-background" css={{ minHeight: 380 }}>
        <div
          className={"container mx-auto px-4 "}
          css={{
            paddingTop: 10,
            paddingBottom: 60,
          }}
        >
          <div
            className={"shadow-sm rounded-md"}
            css={{
              backgroundColor: "#fff",
              marginTop: 50,
            }}
          >
            <div css={{ padding: 48, paddingBottom: 68 }}>
              <div className={"text-2xl font-medium text-gray-600"}>
                Mời chọn nhu cầu để tìm ra sản phẩm bảo hiểm phù hợp nhất cho
                bạn.
              </div>

              <div css={{ paddingTop: 20 }}>
                {this.generateInsurancePlanButtons()}
              </div>
              <div
                className={"text-sm font-medium text-gray-500"}
                css={{ paddingTop: 20 }}
              >
                * Xin vui lòng chọn {config.max_plan} nhu cầu
              </div>
              <div css={{ paddingTop: 20 }}></div>
            </div>
            <Affix offsetBottom={0}>
              <div className="w-full h-24 bg-gray-300 rounded-b flex justify-end">
                <button
                  className="rounded-md my-4 bg-white transition duration-150
                  ease-in-out focus:outline-none rounded text-gray-800
                  border-gray-300 px-8 py-3 text-xl leading-6 border-2"
                  css={{
                    marginRight: 20,
                    padding: "10px 50px",
                    borderColor: "#f76420",
                    backgroundColor: "#f76420",
                    color: "#fff",
                  }}
                  onClick={() => {
                    this.setState({ current_step: 2 });
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-search"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2.5"
                    stroke="#ffffff"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    css={{ float: "left", marginRight: 10 }}
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="10" cy="10" r="7" />
                    <line x1="21" y1="21" x2="15" y2="15" />
                  </svg>
                  <strong>Bắt đầu</strong>
                </button>
              </div>
            </Affix>
          </div>
        </div>
      </div>
    );
  };

  showStep2 = () => {
    window.scrollTo(0, 0);
    return (
      <div className="site-layout-background" css={{ minHeight: 380 }}>
        <div
          className={"container mx-auto px-4 "}
          css={{
            paddingTop: 10,
            paddingBottom: 60,
          }}
        >
          <div
            className={"shadow-sm rounded-md"}
            css={{
              backgroundColor: "#fff",
              marginTop: 50,
            }}
          >
            <div css={{ padding: "28px 48px" }}>
              <div className={"text-2xl font-medium text-gray-600"}>
                Bạn muốn chọn sp tích luỹ/ không tích luỹ ?
              </div>

              <div css={{ paddingTop: 20 }}>
                <button
                  className="rounded-3xl my-2 bg-white transition duration-150
                  ease-in-out focus:outline-none rounded text-gray-800 border-gray-300
                   px-8 py-3 text-sm leading-6 border-2"
                  css={{
                    marginRight: 20,
                    borderColor: "#f76420",

                    backgroundColor: this.state.show_life_insurance_products
                      ? "#f76420"
                      : "#fff",
                    color: this.state.show_life_insurance_products
                      ? "#fff"
                      : "rgba(45, 55, 72, var(--text-opacity))",
                  }}
                  onClick={() => {
                    this.state.show_life_insurance_products = true;
                    this.setState({ show_life_insurance_products: true });
                  }}
                >
                  Tích luỹ
                </button>

                <button
                  className="rounded-3xl my-2 bg-white transition duration-150
                  ease-in-out focus:outline-none rounded text-gray-800 border-gray-300
                   px-8 py-3 text-sm leading-6 border-2"
                  css={{
                    marginRight: 20,
                    borderColor: "#f76420",

                    backgroundColor: !this.state.show_life_insurance_products
                      ? "#f76420"
                      : "#fff",
                    color: !this.state.show_life_insurance_products
                      ? "#fff"
                      : "rgba(45, 55, 72, var(--text-opacity))",
                  }}
                  onClick={() => {
                    this.state.show_life_insurance_products = false;
                    this.setState({ show_life_insurance_products: false });
                  }}
                >
                  Không tích luỹ
                </button>
              </div>

              <div css={{ paddingTop: 20 }}></div>
            </div>

            {this.generateInsurancePlanQuestions()}

            <Affix offsetBottom={0}>
              <div className="w-full bg-gray-300 rounded-b flex justify-between">
                <button
                  className="rounded-md my-4 bg-white transition duration-150
                  ease-in-out focus:outline-none rounded text-gray-800
                  border-gray-300 px-8 py-3 text-xl leading-6  border-2"
                  css={{
                    marginLeft: 20,
                    padding: "10px 50px",
                    // borderColor: "#f76420",
                    backgroundColor: "#333",
                    color: "#fff",
                  }}
                  onClick={() => {
                    this.setState({
                      current_step: 1,
                      selected_insurance_plans: [],
                    });
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-arrow-left"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#fff"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    css={{ float: "left", marginRight: 10 }}
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="5" y1="12" x2="19" y2="12" />
                    <line x1="5" y1="12" x2="11" y2="18" />
                    <line x1="5" y1="12" x2="11" y2="6" />
                  </svg>

                  <strong>Quay lại</strong>
                </button>

                <button
                  className="rounded-md my-4 bg-white transition duration-150
                  ease-in-out focus:outline-none rounded text-gray-800
                  border-gray-300 px-8 py-3 text-xl leading-6  border-2"
                  css={{
                    marginRight: 20,
                    padding: "10px 50px",
                    borderColor: "#f76420",
                    backgroundColor: "#f76420",
                    color: "#fff",
                  }}
                  onClick={() => {
                    this.submitCustomerPlan();

                    this.setState(
                      {
                        current_step: 3,
                        is_loading: true,
                        insurance_products: [],
                        selected_compare_items: [],
                      },
                      () => {
                        let params = {
                          tags: this.state.selected_insurance_tags.toString(),
                          ordering: this.state.show_life_insurance_products
                            ? "-mscore"
                            : "-pscore",
                        };

                        this.getProducts(params);
                      }
                    );
                  }}
                >
                  <strong>Tiếp tục</strong>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-arrow-right"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#ffffff"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    css={{ float: "right", marginLeft: 10 }}
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="5" y1="12" x2="19" y2="12" />
                    <line x1="13" y1="18" x2="19" y2="12" />
                    <line x1="13" y1="6" x2="19" y2="12" />
                  </svg>
                </button>
              </div>
            </Affix>
          </div>
        </div>
      </div>
    );
  };

  showStep3LifeInsurance = () => {
    window.scrollTo(0, 0);
    return (
      <div className="site-layout-background" css={{ minHeight: 380 }}>
        <div
          className={" mx-auto px-4 "}
          css={{
            paddingTop: 10,
            paddingBottom: 0,
          }}
        >
          <button
            className="rounded-md bg-white transition duration-150
            ease-in-out focus:outline-none rounded text-gray-800
            border-gray-300 px-4 py-2 text-md"
            css={{
              backgroundColor: "#8a8a8a",
              color: "#fff",
              margin: "10px 0",
            }}
            onClick={() => {
              this.setState({
                current_step: 2,
                selected_compare_items: [],
                insurance_products: [],
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-arrow-left"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#fff"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              css={{ float: "left", marginRight: 10 }}
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <line x1="5" y1="12" x2="19" y2="12" />
              <line x1="5" y1="12" x2="11" y2="18" />
              <line x1="5" y1="12" x2="11" y2="6" />
            </svg>

            <strong>Quay lại</strong>
          </button>
        </div>
        <div
          className={"container mx-auto px-4 "}
          css={{
            paddingTop: 10,
            paddingBottom: 60,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col col={1}>
              <div css={{ marginTop: 10, maxWidth: 240 }}>
                <div className="w-full rounded-b"></div>
                <div
                  className={"shadow-sm rounded-md"}
                  css={{
                    backgroundColor: "#fff",
                    padding: 24,
                  }}
                >
                  <h4 className="text-md font-semibold dark-gray pt-1.5 text-gray-500">
                    Bộ lọc sản phẩm
                  </h4>
                  <div className="flex items-center justify-between mt-8 mb-2">
                    <h4 className="text-xl font-semibold dark-gray text-gray-700">
                      Công ty Bảo Hiểm
                    </h4>
                  </div>
                  <div>
                    <ul className="cursor-pointer dark-gray ">
                      {this.state.companies.length > 0
                        ? this.state.companies.map((company, index) => {
                            if (
                              company.company_type === "life_insurance" ||
                              company.company_type === "all"
                            ) {
                              return (
                                <li
                                  key={index}
                                  className="mt-2 mb-2 text-base text-md hover:opacity-75"
                                >
                                  <div
                                    onClick={() => {
                                      this.getProductsByCompany(company.id);
                                    }}
                                  >
                                    {company.name}
                                  </div>
                                </li>
                              );
                            }

                            return <React.Fragment></React.Fragment>;
                          })
                        : ""}
                    </ul>
                  </div>
                  <hr />

                  <div className="flex items-center justify-between mt-8 mb-2">
                    <h4 className="text-xl font-semibold dark-gray text-gray-700">
                      Sắp xếp theo
                    </h4>
                  </div>
                  <div>
                    <ul className="cursor-pointer dark-gray">
                      <li className="mt-5 mb-4 text-md hover:opacity-75">
                        <div
                          onClick={() => {
                            this.getProductsByOrdering("-mscore");
                          }}
                        >
                          Quyền lợi bảo vệ sinh mạng: Cao đến thấp
                        </div>
                      </li>

                      <li className="mb-4 text-md hover:opacity-75">
                        <div
                          onClick={() => {
                            this.getProductsByOrdering("-siscore");
                          }}
                        >
                          Quyền lợi chữa bệnh lý nghiêm trọng: Cao đến thấp
                        </div>
                      </li>
                      <li className="mb-4 text-md hover:opacity-75">
                        <div
                          onClick={() => {
                            this.getProductsByOrdering("-hcscore");
                          }}
                        >
                          Quyền lợi thẻ sức khoẻ: Cao đến thấp
                        </div>
                      </li>
                      <li className="mb-4 text-md hover:opacity-75">
                        <div
                          onClick={() => {
                            this.getProductsByOrdering("-ascore");
                          }}
                        >
                          Quyền lợi tai nạn: Cao đến thấp
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
            <Col flex={9}>
              {!this.state.is_loading ? (
                <React.Fragment>
                  {this.state.insurance_products.map((product, index) => {
                    return (
                      <LifeInsuranceItem
                        key={index}
                        product={product}
                        setPropState={this.setPropState}
                        addCompareItem={this.addCompareItem}
                      />
                    );
                  })}
                </React.Fragment>
              ) : (
                <LoadingBox />
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  /***
   * showStep3HealthInsurance
   */
  showStep3HealthInsurance = () => {
    window.scrollTo(0, 0);
    return (
      <div className="site-layout-background" css={{ minHeight: 380 }}>
        <div
          className={" mx-auto px-4 "}
          css={{
            paddingTop: 10,
            paddingBottom: 0,
          }}
        >
          <button
            className="rounded-md bg-white transition duration-150 ease-in-out
                    focus:outline-none rounded text-gray-800 border-gray-300 px-4 py-2 text-md"
            css={{
              backgroundColor: "#8a8a8a",
              color: "#fff",
              margin: "10px 0",
            }}
            onClick={() => {
              this.setState({
                current_step: 2,
                selected_compare_items: [],
                insurance_products: [],
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-arrow-left"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#fff"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              css={{ float: "left", marginRight: 10 }}
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <line x1="5" y1="12" x2="19" y2="12" />
              <line x1="5" y1="12" x2="11" y2="18" />
              <line x1="5" y1="12" x2="11" y2="6" />
            </svg>

            <strong>Quay lại</strong>
          </button>
        </div>
        <div
          className={"container mx-auto px-4 "}
          css={{
            paddingTop: 10,
            paddingBottom: 60,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col col={1}>
              <div css={{ marginTop: 10, maxWidth: 240 }}>
                <div className="w-full rounded-b"></div>
                <div
                  className={"shadow-sm rounded-md"}
                  css={{
                    backgroundColor: "#fff",
                    padding: 24,
                  }}
                >
                  <h4 className="text-md font-semibold dark-gray pt-1.5 text-gray-500">
                    Bộ lọc sản phẩm
                  </h4>

                  <div className="flex items-center justify-between mt-8 mb-2">
                    <h4 className="text-xl font-semibold dark-gray text-gray-700">
                      Khuyến mãi
                    </h4>
                  </div>
                  <div>
                    <ul className="cursor-pointer dark-gray ">
                      <li className="mt-2 mb-2 text-base text-md hover:opacity-75">
                        <div
                          onClick={() => {
                            this.setState(
                              {
                                current_step: 3,
                                is_loading: true,
                                insurance_products: [],
                                selected_compare_items: [],
                              },
                              () => {
                                let params = {
                                  tags: this.state.selected_insurance_tags.toString(),
                                  ordering: this.state
                                    .show_life_insurance_products
                                    ? "-mscore"
                                    : "-pscore",
                                };

                                this.getProducts(params);
                              }
                            );
                          }}
                        >
                          Hiện tất cả SP
                        </div>
                      </li>

                      <li className="mt-5 mb-4 text-base text-md hover:opacity-75">
                        <div
                          onClick={() => {
                            this.getProductsByDiscount("True");
                          }}
                        >
                          Chỉ hiện SP có khuyến mãi
                        </div>
                      </li>
                    </ul>
                  </div>
                  <hr />

                  <div className="flex items-center justify-between mt-8 mb-2">
                    <h4 className="text-xl font-semibold dark-gray text-gray-700">
                      Công ty Bảo Hiểm
                    </h4>
                  </div>
                  <div>
                    <ul className="cursor-pointer dark-gray ">
                      {this.state.companies.length > 0
                        ? this.state.companies.map((company, index) => {
                            if (
                              company.company_type === "health_insurance" ||
                              company.company_type === "all"
                            ) {
                              return (
                                <li
                                  key={index}
                                  className="mt-5 mb-4 text-base text-md hover:opacity-75"
                                >
                                  <div
                                    onClick={() => {
                                      this.getProductsByCompany(company.id);
                                    }}
                                  >
                                    {company.name}
                                  </div>
                                </li>
                              );
                            }

                            return <React.Fragment></React.Fragment>;
                          })
                        : ""}
                    </ul>
                  </div>
                  <hr />

                  <div className="flex items-center justify-between mt-8 mb-2">
                    <h4 className="text-xl font-semibold dark-gray text-gray-700">
                      Sắp xếp theo
                    </h4>
                  </div>
                  <div>
                    <ul className="cursor-pointer dark-gray">
                      <li className="mt-5 mb-4 text-md hover:opacity-75">
                        <div
                          onClick={() => {
                            this.getProductsByOrdering("-discount");
                          }}
                        >
                          Khuyến mãi
                        </div>
                      </li>

                      <li className="mt-5 mb-4 text-md hover:opacity-75">
                        <div
                          onClick={() => {
                            this.getProductsByOrdering("price");
                          }}
                        >
                          Giá thấp đến cao
                        </div>
                      </li>

                      <li className="mt-5 mb-4 text-md hover:opacity-75">
                        <div
                          onClick={() => {
                            this.getProductsByOrdering("-price");
                          }}
                        >
                          Giá cao đến thấp
                        </div>
                      </li>

                      <li className="mt-5 mb-4 text-md hover:opacity-75">
                        <div
                          onClick={() => {
                            this.getProductsByOrdering("-pscore");
                          }}
                        >
                          Đánh gia chung cao đến thấp
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* <hr /> */}
                </div>
              </div>
            </Col>
            <Col flex={9}>
              {!this.state.is_loading ? (
                <React.Fragment>
                  <div
                    className="site-card-wrapper bhv-card-wrapper"
                    css={{ maxWidth: 990 }}
                  >
                    {chunkArray(this.state.insurance_products, 3).map(
                      (chunk, index) => {
                        return (
                          <Row gutter={[16, 16]} key={index}>
                            {chunk.map((product, pindex) => {
                              return (
                                <HealthInsuranceItem
                                  key={pindex}
                                  product={product}
                                  setPropState={this.setPropState}
                                  addCompareItem={this.addCompareItem}
                                />
                              );
                            })}
                          </Row>
                        );
                      }
                    )}
                  </div>
                </React.Fragment>
              ) : (
                <LoadingBox />
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  showStep4 = () => {
    window.scrollTo(0, 0);
    return (
      <div className="site-layout-background" css={{ minHeight: 380 }}>
        <div
          className={"container mx-auto px-4 "}
          css={{
            paddingTop: 10,
            paddingBottom: 60,
          }}
        >
          <div
            className={"shadow-sm rounded-md"}
            css={{
              backgroundColor: "#fff",
              marginTop: 50,
            }}
          >
            <div className="xl:w-full border-b border-gray-300 py-6">
              <div className="flex items-center w-11/12 mx-auto">
                <div className="text-lg text-gray-800 font-bold">
                  Đăng ký nhận tư vấn bảo hiểm.
                </div>
                <div className="ml-2 cursor-pointer text-gray-600">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={16}
                    height={16}
                  >
                    <path
                      className="heroicon-ui"
                      d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm0-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-11/12 mx-auto">
              <div className="container mx-auto">
                <div
                  className="my-6 mx-auto xl:w-full xl:mx-0"
                  style={{ marginTop: 20, marginBottom: 30 }}
                >
                  <div className="xl:w-full lg:w-full md:w-full flex flex-col mb-4">
                    <label
                      htmlFor="email2"
                      className="pb-2 text-sm font-bold text-gray-800"
                    >
                      Số điện thoại
                    </label>
                    <div className="relative">
                      <div className="absolute text-gray-600 flex items-center px-4 border-r h-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                          <path d="M15 7a2 2 0 0 1 2 2" />
                          <path d="M15 3a6 6 0 0 1 6 6" />
                        </svg>
                      </div>
                      <input
                        value={this.state.phone}
                        onChange={(e) => {
                          this.setState({ phone: e.target.value });
                        }}
                        id="phone"
                        name="phone"
                        required
                        className="w-full text-gray-800 focus:outline-none
                        focus:border focus:border-indigo-700 font-normal py-2 flex
                        items-center pl-16 text-sm rounded border"
                        placeholder="Mời bạn nhập SĐT"
                      />
                    </div>
                  </div>

                  <div className="xl:w-full lg:w-full md:w-full flex flex-col mb-4">
                    <label
                      htmlFor="FirstName"
                      className="pb-2 text-sm font-bold text-gray-800"
                    >
                      Họ tên
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      required
                      id="FirstName"
                      className="border border-gray-300 pl-3 py-2 shadow-sm rounded text-sm
                       focus:outline-none focus:border-indigo-700 text-gray-800"
                      placeholder="Mời bạn nhập họ tên"
                      value={this.state.name}
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                      }}
                    />
                  </div>

                  <div className="xl:w-full lg:w-full md:w-full flex flex-col mb-4">
                    <label
                      htmlFor="email2"
                      className="pb-2 text-sm font-bold text-gray-800"
                    >
                      Email
                    </label>
                    <div className="relative">
                      <div className="absolute text-gray-600 flex items-center px-4 border-r h-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-mail"
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <rect x={3} y={5} width={18} height={14} rx={2} />
                          <polyline points="3 7 12 13 21 7" />
                        </svg>
                      </div>
                      <input
                        value={this.state.email}
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                        id="email2"
                        name="email"
                        required
                        className="w-full text-gray-800 focus:outline-none focus:border
                        focus:border-indigo-700 font-normal py-2 flex items-center pl-16 text-sm rounded border"
                        placeholder="example@gmail.com"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Affix offsetBottom={0}>
              <div className="w-full h-24 bg-gray-300 rounded-b flex justify-between">
                <button
                  className="rounded-md my-4 bg-white transition duration-150
                  ease-in-out focus:outline-none rounded text-gray-800
                  border-gray-300 px-8 py-3 text-xl leading-6  border-2"
                  css={{
                    marginLeft: 20,
                    padding: "10px 50px",
                    backgroundColor: "#333",
                    color: "#fff",
                  }}
                  onClick={() => {
                    this.setState({ current_step: 3 });
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-arrow-left"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#fff"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    css={{ float: "left", marginRight: 10 }}
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="5" y1="12" x2="19" y2="12" />
                    <line x1="5" y1="12" x2="11" y2="18" />
                    <line x1="5" y1="12" x2="11" y2="6" />
                  </svg>

                  <strong>Quay lại</strong>
                </button>

                <button
                  className="rounded-md my-4 bg-white transition duration-150
                  ease-in-out focus:outline-none rounded text-gray-800
                  border-gray-300 px-8 py-3 text-xl leading-6  border-2"
                  css={{
                    marginRight: 20,
                    padding: "10px 50px",
                    borderColor: "#f76420",
                    backgroundColor: "#f76420",
                    color: "#fff",
                  }}
                  onClick={() => {
                    this.submitCustomer();
                  }}
                >
                  <strong>Gửi tư vấn</strong>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-arrow-right"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#ffffff"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    css={{ float: "right", marginLeft: 10 }}
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="5" y1="12" x2="19" y2="12" />
                    <line x1="13" y1="18" x2="19" y2="12" />
                    <line x1="13" y1="6" x2="19" y2="12" />
                  </svg>
                </button>
              </div>
            </Affix>
          </div>
        </div>
      </div>
    );
  };

  generateLifeInsuranceBenefitCompare = () => {
    let showable = checkNested(
      this.state.benefit_types,
      "life_insurance",
      "benefit_types"
    )
      ? this.state.benefit_types.life_insurance.benefit_types.length > 0
        ? true
        : false
      : false;

    if (showable) {
      let btypes = this.state.benefit_types.life_insurance.benefit_types;
      let items = this.state.selected_compare_items;
      return (
        <React.Fragment>
          {btypes.map((btype, index) => {
            return (
              <Row
                gutter={[16, 16]}
                key={index}
                css={{ borderBottomWidth: "1px" }}
              >
                <Col
                  span={4}
                  css={{ borderRightWidth: "1px", borderLeftWidth: "1px" }}
                >
                  <div
                    css={{
                      width: "100%",
                      float: "left",
                      marginRight: "10px",
                      color: "#333",
                      paddingTop: 6,
                      paddingRight: 6,
                      paddingLeft: 6,
                      position: "relative",
                    }}
                  >
                    {btype.name}
                  </div>
                </Col>
                {items.map((item, index) => {
                  if (item.benefits.length > 0) {
                    let benefit;
                    for (let i = 0; i < item.benefits.length; i++) {
                      if (checkNested(item.benefits[i], "benefit_type", "id")) {
                        if (item.benefits[i].benefit_type.id === btype.id) {
                          benefit = item.benefits[i];
                        }
                      }
                    }

                    return (
                      <React.Fragment>
                        <Col span={5} css={{ borderRightWidth: "1px" }}>
                          <div
                            className={"text-left"}
                            css={{
                              float: "left",
                              marginRight: "10px",
                              color: "#333",
                              paddingTop: 6,
                              paddingRight: 20,
                              paddingLeft: 20,
                              position: "relative",
                              fontSize: 12,
                            }}
                            onClick={() => {}}
                          >
                            {benefit
                              ? shortDescription(benefit.short_description)
                              : ""}
                          </div>
                        </Col>
                      </React.Fragment>
                    );
                  }

                  return <React.Fragment></React.Fragment>;
                })}
              </Row>
            );
          })}
        </React.Fragment>
      );
    }

    return <React.Fragment></React.Fragment>;
  };

  generateLifeInsuranceAddonProductCompare = () => {
    let showable = checkNested(
      this.state.benefit_types,
      "life_insurance",
      "addon_product_types"
    )
      ? this.state.benefit_types.life_insurance.addon_product_types.length > 0
        ? true
        : false
      : false;

    if (showable) {
      let btypes = this.state.benefit_types.life_insurance.addon_product_types;
      let items = this.state.selected_compare_items;
      return (
        <React.Fragment>
          {btypes.map((btype, index) => {
            return (
              <Row gutter={[16, 16]} css={{ borderBottomWidth: "1px" }}>
                <Col
                  span={4}
                  css={{ borderRightWidth: "1px", borderLeftWidth: "1px" }}
                >
                  <div
                    css={{
                      width: "100%",
                      float: "left",
                      marginRight: "10px",
                      color: "#333",
                      paddingTop: 6,
                      paddingRight: 6,
                      paddingLeft: 6,
                      position: "relative",
                    }}
                  >
                    {btype.name}
                  </div>
                </Col>
                {items.map((item, index) => {
                  if (item.addon_products.length > 0) {
                    let benefit;
                    for (let i = 0; i < item.addon_products.length; i++) {
                      if (item.addon_products[i].add_on_type.id === btype.id) {
                        benefit = item.addon_products[i];
                      }
                    }

                    return (
                      <React.Fragment>
                        <Col span={5} css={{ borderRightWidth: "1px" }}>
                          <div
                            className={"text-left"}
                            css={{
                              float: "left",
                              marginRight: "10px",
                              color: "#333",
                              paddingTop: 6,
                              paddingRight: 20,
                              paddingLeft: 20,
                              position: "relative",
                              fontSize: 12,
                            }}
                            onClick={() => {}}
                          >
                            {benefit
                              ? shortDescription(benefit.short_description)
                              : ""}
                          </div>
                        </Col>
                      </React.Fragment>
                    );
                  }

                  return <React.Fragment></React.Fragment>;
                })}
              </Row>
            );
          })}
        </React.Fragment>
      );
    }

    return <React.Fragment></React.Fragment>;
  };

  showCompareTableLifeInsurance = () => {
    return (
      <React.Fragment>
        <Affix offsetTop={0}>
          <div
            css={{
              backgroundColor: "#dadada",
            }}
          >
            <Content
              className="site-layout"
              css={{
                padding: "0 50px",
                color: "#fff",
              }}
            >
              <div className="site-layout-background" css={{ minHeight: 60 }}>
                <div
                  className={"container mx-auto  "}
                  css={{
                    paddingTop: 4,
                  }}
                >
                  <Row gutter={[16, 16]} css={{ borderBottomWidth: "1px" }}>
                    <Col span={4} css={{}}>
                      <div
                        css={{
                          height: 64,
                          width: "100%",
                          float: "left",
                          marginRight: "10px",
                          color: "#333",
                          paddingTop: 16,
                          paddingRight: 6,
                          paddingLeft: 6,
                          position: "relative",
                        }}
                      >
                        <strong>Sản phẩm</strong>
                      </div>
                    </Col>
                    {this.state.selected_compare_items.map((val, index) => {
                      return (
                        <React.Fragment>
                          <Col span={5}>
                            <div
                              className={"rounded-md text-center"}
                              css={{
                                height: 64,
                                width: "100%",
                                background: "#dadada",
                                float: "left",
                                marginRight: "10px",
                                color: "#333",
                                paddingTop: 6,
                                paddingRight: 6,
                                paddingLeft: 6,
                                position: "relative",
                              }}
                              onClick={() => {
                                this.removeCompareItem(val);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-x"
                                width="12"
                                height="12"
                                viewBox="0 0 24 24"
                                strokeWidth="2.5"
                                stroke="#9e9e9e"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                css={{
                                  position: "absolute",
                                  top: 4,
                                  right: 4,
                                }}
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                              </svg>
                              <strong>{val.company.name}</strong>
                              <br />
                              <div css={{ fontSize: 11 }}>{val.name}</div>
                            </div>
                          </Col>
                        </React.Fragment>
                      );
                    })}
                  </Row>
                </div>
              </div>
            </Content>
          </div>
        </Affix>
        <div
          css={{
            minHeight: 1000,
          }}
        >
          <Content
            className="site-layout"
            css={{
              padding: "0 50px",
              color: "#fff",
            }}
          >
            <div className="site-layout-background" css={{ minHeight: 60 }}>
              <div
                className={"container mx-auto  "}
                css={{
                  paddingTop: 4,
                  paddingBottom: 4,
                  // borderBottomWidth: "1px",
                }}
              >
                <Row
                  gutter={[16, 16]}
                  css={{ borderBottomWidth: "1px", borderTopWidth: "1px" }}
                >
                  <Col
                    span={4}
                    css={{
                      borderRightWidth: "1px",
                      borderLeftWidth: "1px",
                    }}
                  >
                    <div
                      css={{
                        width: "100%",
                        float: "left",
                        marginRight: "10px",
                        paddingTop: 6,
                        paddingRight: 6,
                        paddingLeft: 6,
                        position: "relative",
                        color: config.color.primary,
                      }}
                    >
                      <strong>Lý do lựa chọn</strong>
                    </div>
                  </Col>
                  {this.state.selected_compare_items.map((val, index) => {
                    return (
                      <React.Fragment>
                        <Col
                          span={5}
                          css={{
                            borderRightWidth: "1px",
                          }}
                        >
                          <div
                            className={"text-left"}
                            css={{
                              float: "left",
                              marginRight: "10px",
                              color: "#333",
                              paddingTop: 6,
                              paddingRight: 20,
                              paddingLeft: 20,
                              position: "relative",
                              fontSize: 12,
                            }}
                            onClick={() => {}}
                          >
                            <div className="text-gray-700">
                              <div>
                                {val.reason_1_title ? val.reason_1_title : ""}
                              </div>

                              <div>
                                {val.reason_2_title ? val.reason_2_title : ""}
                              </div>

                              <div>
                                {val.reason_3_title ? val.reason_3_title : ""}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </React.Fragment>
                    );
                  })}
                </Row>
                {/*
                <Row gutter={[16, 16]} css={{ borderBottomWidth: "1px" }}>
                  <Col
                    span={4}
                    css={{
                      borderRightWidth: "1px",
                      borderLeftWidth: "1px",
                    }}
                  >
                    <div
                      css={{
                        width: "100%",
                        float: "left",
                        marginRight: "10px",
                        color: "#333",
                        paddingTop: 6,
                        paddingRight: 6,
                        paddingLeft: 6,
                        position: "relative",
                        color: config.color.primary,
                      }}
                    >
                      <strong>Đặc điểm chính</strong>
                    </div>
                  </Col>
                  {this.state.selected_compare_items.map((val, index) => {
                    return (
                      <React.Fragment>
                        <Col span={5} css={{ borderRightWidth: "1px" }}></Col>
                      </React.Fragment>
                    );
                  })}
                </Row>

                <TableRow
                  title={"Phí thường niên"}
                  field={"annual_fee"}
                  items={this.state.selected_compare_items}
                  itemComponent={FreeRowItem}
                />

                <TableRow
                  title={"Tích luỹ dự kiến"}
                  field={"expected_accumulation"}
                  items={this.state.selected_compare_items}
                  itemComponent={FreeRowItem}
                />

                <TableRow
                  title={"Tích luỹ đảm bảo"}
                  field={"guaranteed_accumulation"}
                  items={this.state.selected_compare_items}
                  itemComponent={FreeRowItem}
                /> */}

                <Collapse
                  accordion
                  bordered={false}
                  defaultActiveKey={["1"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header="Thông tin chung"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <Row
                      gutter={[16, 16]}
                      css={{
                        borderBottomWidth: "1px",
                        borderTopWidth: "1px",
                      }}
                    >
                      <Col
                        span={4}
                        css={{
                          borderRightWidth: "1px",
                          borderLeftWidth: "1px",
                        }}
                      >
                        <div
                          css={{
                            width: "100%",
                            float: "left",
                            marginRight: "10px",
                            paddingTop: 6,
                            paddingRight: 6,
                            paddingLeft: 6,
                            position: "relative",
                            color: config.color.primary,
                          }}
                        >
                          <strong>Thông tin chung</strong>
                        </div>
                      </Col>
                      {this.state.selected_compare_items.map((val, index) => {
                        return (
                          <React.Fragment>
                            <Col
                              span={5}
                              css={{ borderRightWidth: "1px" }}
                            ></Col>
                          </React.Fragment>
                        );
                      })}
                    </Row>

                    <TableRow
                      title={"Tuổi tham gia"}
                      field={"age_of_participation"}
                      items={this.state.selected_compare_items}
                      itemComponent={SimpleRowItem}
                    />

                    <TableRow
                      title={"Bảo vệ đến (bao nhiêu tuổi)"}
                      field={"protect_to_age"}
                      items={this.state.selected_compare_items}
                      itemComponent={SimpleRowItem}
                    />

                    <TableRow
                      title={"Quỹ đầu tư"}
                      field={"investment_funds"}
                      items={this.state.selected_compare_items}
                      itemComponent={SimpleRowItem}
                    />

                    <TableRow
                      title={"Tỷ suất đầu tư"}
                      field={"investment_rate"}
                      items={this.state.selected_compare_items}
                      itemComponent={SimpleRowItem}
                    />

                    {/* <TableRow
                      title={"Phí BH cơ bản"}
                      field={"basic_premium"}
                      items={this.state.selected_compare_items}
                      itemComponent={SimpleRowItem}
                    />

                    <TableRow
                      title={"Phí BH đóng thêm"}
                      field={"premium_premiums"}
                      items={this.state.selected_compare_items}
                      itemComponent={SimpleRowItem}
                    /> */}

                    <TableRow
                      title={"Phí BH ban đầu"}
                      field={"original_premium"}
                      items={this.state.selected_compare_items}
                      itemComponent={SimpleRowItem}
                    />
                  </Panel>

                  <Panel
                    header="Sản phẩm chính"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <Row
                      gutter={[16, 16]}
                      css={{
                        borderBottomWidth: "1px",
                        borderTopWidth: "1px",
                      }}
                    >
                      <Col
                        span={4}
                        css={{
                          borderRightWidth: "1px",
                          borderLeftWidth: "1px",
                        }}
                      >
                        <div
                          css={{
                            width: "100%",
                            float: "left",
                            marginRight: "10px",
                            paddingTop: 6,
                            paddingRight: 6,
                            paddingLeft: 6,
                            position: "relative",
                            color: config.color.primary,
                          }}
                        >
                          <strong>Sản phẩm chính</strong>
                        </div>
                      </Col>
                      {this.state.selected_compare_items.map((val, index) => {
                        return (
                          <React.Fragment>
                            <Col
                              span={5}
                              css={{ borderRightWidth: "1px" }}
                            ></Col>
                          </React.Fragment>
                        );
                      })}
                    </Row>

                    {this.generateLifeInsuranceBenefitCompare()}
                  </Panel>

                  <Panel
                    header="Sản phẩm bổ trợ"
                    key="3"
                    className="site-collapse-custom-panel"
                  >
                    <Row
                      gutter={[16, 16]}
                      css={{
                        borderBottomWidth: "1px",
                        borderTopWidth: "1px",
                      }}
                    >
                      <Col
                        span={4}
                        css={{
                          borderRightWidth: "1px",
                          borderLeftWidth: "1px",
                        }}
                      >
                        <div
                          css={{
                            width: "100%",
                            float: "left",
                            marginRight: "10px",
                            paddingTop: 6,
                            paddingRight: 6,
                            paddingLeft: 6,
                            position: "relative",
                            color: config.color.primary,
                          }}
                        >
                          <strong>Sản phẩm bổ trợ</strong>
                        </div>
                      </Col>
                      {this.state.selected_compare_items.map((val, index) => {
                        return (
                          <React.Fragment>
                            <Col
                              span={5}
                              css={{ borderRightWidth: "1px" }}
                            ></Col>
                          </React.Fragment>
                        );
                      })}
                    </Row>

                    {this.generateLifeInsuranceAddonProductCompare()}
                  </Panel>
                </Collapse>
              </div>
            </div>
          </Content>
        </div>
        <Affix offsetBottom={-10}>
          <div
            css={{
              backgroundColor: "#333",
            }}
          >
            <Content
              className="site-layout"
              css={{
                padding: "0 50px",
                color: "#fff",
              }}
            >
              <div className="site-layout-background" css={{ minHeight: 60 }}>
                <div
                  className={"container mx-auto  "}
                  css={{
                    paddingTop: 4,
                  }}
                >
                  <Row gutter={[16, 16]}>
                    <Col flex={9}></Col>

                    <Col col={1}></Col>

                    <Col col={1}>
                      <button
                        className="rounded-md bg-white transition duration-150
              ease-in-out focus:outline-none rounded text-gray-800
              border-gray-300 px-2 py-1 text-sm"
                        css={{
                          backgroundColor: "#8a8a8a",
                          color: "#fff",
                          margin: "10px 0",
                          paddingRight: 10,
                          paddingLeft: 10,
                        }}
                        onClick={() => {
                          this.setState({ show_compare_table: false });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-square-x"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#fff"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          css={{ float: "left", marginRight: 10 }}
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <rect x="4" y="4" width="16" height="16" rx="2" />
                          <path d="M10 10l4 4m0 -4l-4 4" />
                        </svg>

                        <strong>Ẩn bảng so sánh</strong>
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Content>
          </div>
        </Affix>
      </React.Fragment>
    );
  };

  genenerateHealthInsuranceSubBenefits = (list, benefitKey) => {
    let items = this.state.selected_compare_items;
    let subTypes = this.state.benefit_types.health_insurance.sub_benefit_types;

    return list.map((benefit, bindex) => {
      let benefitName;

      for (let i = 0; i < subTypes.length; i++) {
        let type = subTypes[i];
        if (type.id === benefit) {
          benefitName = type.name;
        }
      }

      return (
        <React.Fragment>
          <Row
            gutter={[16, 16]}
            key={bindex}
            css={{ borderBottomWidth: "1px" }}
          >
            <Col
              span={4}
              css={{
                borderRightWidth: "1px",
                borderLeftWidth: "1px",
              }}
            >
              <div
                css={{
                  width: "100%",
                  float: "left",
                  marginRight: "10px",
                  color: "#333",
                  paddingTop: 6,
                  paddingRight: 6,
                  paddingLeft: 6,
                  position: "relative",
                }}
              >
                {benefitName}
              </div>
            </Col>

            {items.map((item, index) => {
              if (item.benefits.length > 0) {
                let ibenefit;
                for (let i = 0; i < item.benefits.length; i++) {
                  if (benefitKey === item.benefits[i].type) {
                    ibenefit = item.benefits[i];
                  }
                }

                return (
                  <React.Fragment>
                    <Col span={5} css={{ borderRightWidth: "1px" }}>
                      <div
                        className={"text-left"}
                        css={{
                          float: "left",
                          marginRight: "10px",
                          color: "#333",
                          paddingTop: 6,
                          paddingRight: 20,
                          paddingLeft: 20,
                          position: "relative",
                          fontSize: 12,
                        }}
                        onClick={() => {}}
                      >
                        <div className="text-gray-700">
                          {ibenefit.sub_benefits.length > 0
                            ? ibenefit.sub_benefits.map((sub, index) => {
                                if (sub.sub_benefit_type.id === benefit) {
                                  return (
                                    <React.Fragment key={index}>
                                      <div>{sub.insurance_compensation}</div>
                                      <br />
                                    </React.Fragment>
                                  );
                                } else {
                                  return <React.Fragment></React.Fragment>;
                                }
                              })
                            : ""}
                        </div>
                      </div>
                    </Col>
                  </React.Fragment>
                );
              }

              return <React.Fragment></React.Fragment>;
            })}
          </Row>
        </React.Fragment>
      );
    });
  };

  showCompareTableHealthInsurance = () => {
    let inpatientCareBenefits = [],
      outpatientCareBenefits = [],
      pregnancyCareBenefits = [],
      dentistCareBenefits = [],
      otherBenefits = [];

    let healthSubBenefitTypes = this.state.benefit_types.health_insurance
      .sub_benefit_types;

    let items = this.state.selected_compare_items;

    for (let a = 0; a < items.length; a++) {
      let item = items[a];
      if (item.benefits.length > 0) {
        for (let i = 0; i < item.benefits.length; i++) {
          let bi = item.benefits[i];
          for (let si = 0; si < bi.sub_benefits.length; si++) {
            let sTypeId = bi.sub_benefits[si].sub_benefit_type.id;

            const lamda = (e) => {
              return e === sTypeId;
            };

            if ("inpatient_care" === bi.type) {
              inpatientCareBenefits.pushIfNotExist(sTypeId, lamda);
            } else if ("outpatient_care" === bi.type) {
              outpatientCareBenefits.pushIfNotExist(sTypeId, lamda);
            } else if ("pregnancy_care" === bi.type) {
              pregnancyCareBenefits.pushIfNotExist(sTypeId, lamda);
            } else if ("dentist_care" === bi.type) {
              dentistCareBenefits.pushIfNotExist(sTypeId, lamda);
            } else if ("other" === bi.type) {
              otherBenefits.pushIfNotExist(sTypeId, lamda);
            }
          }
        }
      }
    }

    log(inpatientCareBenefits);
    log(outpatientCareBenefits);
    log(pregnancyCareBenefits);
    log(dentistCareBenefits);
    log(otherBenefits);

    return (
      <React.Fragment>
        <Affix offsetTop={0}>
          <div
            css={{
              backgroundColor: "#dadada",
            }}
          >
            <Content
              className="site-layout"
              css={{
                padding: "0 50px",
                color: "#fff",
              }}
            >
              <div className="site-layout-background" css={{ minHeight: 60 }}>
                <div
                  className={"container mx-auto  "}
                  css={{
                    paddingTop: 4,
                  }}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={4}>
                      <div
                        css={{
                          height: 64,
                          width: "100%",
                          float: "left",
                          marginRight: "10px",
                          color: "#333",
                          paddingTop: 16,
                          paddingRight: 6,
                          paddingLeft: 6,
                          position: "relative",
                        }}
                      >
                        <strong>Sản phẩm</strong>
                      </div>
                    </Col>
                    {items.map((val, index) => {
                      return (
                        <React.Fragment>
                          <Col span={5}>
                            <div
                              className={"rounded-md text-center"}
                              css={{
                                height: 64,
                                width: "100%",
                                background: "#dadada",
                                float: "left",
                                marginRight: "10px",
                                color: "#333",
                                paddingTop: 6,
                                paddingRight: 6,
                                paddingLeft: 6,
                                position: "relative",
                              }}
                              onClick={() => {
                                this.removeCompareItem(val);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-x"
                                width="12"
                                height="12"
                                viewBox="0 0 24 24"
                                strokeWidth="2.5"
                                stroke="#9e9e9e"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                css={{
                                  position: "absolute",
                                  top: 4,
                                  right: 4,
                                }}
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                              </svg>
                              <strong>
                                {checkNested(
                                  val,
                                  "health_insurance_product",
                                  "company",
                                  "name"
                                )
                                  ? val.health_insurance_product.company.name
                                  : ""}
                              </strong>
                              <br />
                              <div css={{ fontSize: 11 }}>{val.name}</div>
                            </div>
                          </Col>
                        </React.Fragment>
                      );
                    })}
                  </Row>
                </div>
              </div>
            </Content>
          </div>
        </Affix>
        <div
          css={{
            minHeight: 1000,
          }}
        >
          <Content
            className="site-layout"
            css={{
              padding: "0 50px",
              color: "#fff",
            }}
          >
            <div className="site-layout-background" css={{ minHeight: 60 }}>
              <div
                className={"container mx-auto  "}
                css={{
                  paddingTop: 4,
                  paddingBottom: 4,
                  // borderBottomWidth: "1px",
                }}
              >
                <Row
                  gutter={[16, 16]}
                  css={{ borderBottomWidth: "1px", borderTopWidth: "1px" }}
                >
                  <Col
                    span={4}
                    css={{
                      borderRightWidth: "1px",
                      borderLeftWidth: "1px",
                    }}
                  >
                    <div
                      css={{
                        width: "100%",
                        float: "left",
                        marginRight: "10px",
                        paddingTop: 6,
                        paddingRight: 6,
                        paddingLeft: 6,
                        position: "relative",
                        color: config.color.primary,
                      }}
                    >
                      <strong>Lý do lựa chọn</strong>
                    </div>
                  </Col>
                  {items.map((val, index) => {
                    return (
                      <React.Fragment>
                        <Col
                          span={5}
                          css={{
                            borderRightWidth: "1px",
                          }}
                        >
                          <div
                            className={"text-left"}
                            css={{
                              maxWidth: items.length > 3 ? 250 : "100%",
                              float: "left",
                              marginRight: "10px",
                              color: "#333",
                              paddingTop: 6,
                              paddingRight: 20,
                              paddingLeft: 20,
                              position: "relative",
                              fontSize: 12,
                            }}
                            onClick={() => {}}
                          >
                            <div className="text-gray-700">
                              <div>
                                {val.health_insurance_product.reason_1_title
                                  ? val.health_insurance_product.reason_1_title
                                  : ""}
                              </div>

                              <div>
                                {val.health_insurance_product.reason_2_title
                                  ? val.health_insurance_product.reason_2_title
                                  : ""}
                              </div>

                              <div>
                                {val.health_insurance_product.reason_3_title
                                  ? val.health_insurance_product.reason_3_title
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </React.Fragment>
                    );
                  })}
                </Row>

                <Row
                  gutter={[16, 16]}
                  css={{ borderBottomWidth: "1px", borderTopWidth: "1px" }}
                >
                  <Col
                    span={4}
                    css={{
                      borderRightWidth: "1px",
                      borderLeftWidth: "1px",
                    }}
                  >
                    <div
                      css={{
                        width: "100%",
                        float: "left",
                        marginRight: "10px",
                        paddingTop: 6,
                        paddingRight: 6,
                        paddingLeft: 6,
                        position: "relative",
                        color: config.color.primary,
                      }}
                    >
                      <strong>Phí bảo hiểm</strong>
                    </div>
                  </Col>
                  {items.map((val, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Col
                          span={5}
                          css={{
                            borderRightWidth: "1px",
                          }}
                        >
                          <div
                            className={"text-left"}
                            css={{
                              float: "left",
                              marginRight: "10px",
                              color: "#333",
                              paddingTop: 6,
                              paddingRight: 20,
                              paddingLeft: 20,
                              position: "relative",
                              fontSize: 12,
                            }}
                            onClick={() => {}}
                          >
                            <div className="text-gray-700">
                              {val.display_price
                                ? parseInt(val.display_price).toLocaleString(
                                    "vi-VN",
                                    config.currency_locale
                                  )
                                : ""}
                            </div>
                          </div>
                        </Col>
                      </React.Fragment>
                    );
                  })}
                </Row>

                <Collapse
                  accordion
                  bordered={false}
                  defaultActiveKey={["1"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header="Nội trú"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <Row
                      gutter={[16, 16]}
                      css={{
                        borderBottomWidth: "1px",
                        borderTopWidth: "1px",
                      }}
                    >
                      <Col
                        span={4}
                        css={{
                          borderRightWidth: "1px",
                          borderLeftWidth: "1px",
                        }}
                      >
                        <div
                          css={{
                            width: "100%",
                            float: "left",
                            marginRight: "10px",
                            paddingTop: 6,
                            paddingRight: 6,
                            paddingLeft: 6,
                            position: "relative",
                            color: config.color.primary,
                          }}
                        >
                          <strong>Nội trú</strong>
                        </div>
                      </Col>
                      {items.map((item, index) => {
                        if (item.benefits.length > 0) {
                          let benefit;
                          for (let i = 0; i < item.benefits.length; i++) {
                            if ("inpatient_care" === item.benefits[i].type) {
                              benefit = item.benefits[i];
                            }
                          }

                          return (
                            <React.Fragment>
                              <Col span={5} css={{ borderRightWidth: "1px" }}>
                                <div
                                  className={"text-left"}
                                  css={{
                                    float: "left",
                                    marginRight: "10px",
                                    color: "#333",
                                    paddingTop: 6,
                                    paddingRight: 20,
                                    paddingLeft: 20,
                                    position: "relative",
                                    fontSize: 12,
                                  }}
                                  onClick={() => {}}
                                >
                                  <div className="text-gray-700">
                                    <div>
                                      <strong>Quyền lợi bảo hiểm:</strong>
                                      <br />
                                      {benefit
                                        ? benefit.insurance_compensation
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </React.Fragment>
                          );
                        }

                        return <React.Fragment></React.Fragment>;
                      })}
                    </Row>

                    {this.genenerateHealthInsuranceSubBenefits(
                      inpatientCareBenefits,
                      "inpatient_care"
                    )}
                  </Panel>

                  <Panel
                    header="Ngoại trú"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <Row
                      gutter={[16, 16]}
                      css={{
                        borderBottomWidth: "1px",
                        borderTopWidth: "1px",
                      }}
                    >
                      <Col
                        span={4}
                        css={{
                          borderRightWidth: "1px",
                          borderLeftWidth: "1px",
                        }}
                      >
                        <div
                          css={{
                            width: "100%",
                            float: "left",
                            marginRight: "10px",
                            paddingTop: 6,
                            paddingRight: 6,
                            paddingLeft: 6,
                            position: "relative",
                            color: config.color.primary,
                          }}
                        >
                          <strong>Ngoại trú</strong>
                        </div>
                      </Col>
                      {items.map((item, index) => {
                        if (item.benefits.length > 0) {
                          let benefit;
                          for (let i = 0; i < item.benefits.length; i++) {
                            if ("outpatient_care" === item.benefits[i].type) {
                              benefit = item.benefits[i];
                            }
                          }

                          return (
                            <React.Fragment>
                              <Col span={5} css={{ borderRightWidth: "1px" }}>
                                <div
                                  className={"text-left"}
                                  css={{
                                    float: "left",
                                    marginRight: "10px",
                                    color: "#333",
                                    paddingTop: 6,
                                    paddingRight: 20,
                                    paddingLeft: 20,
                                    position: "relative",
                                    fontSize: 12,
                                  }}
                                  onClick={() => {}}
                                >
                                  <div className="text-gray-700">
                                    <div>
                                      <strong>Quyền lợi bảo hiểm:</strong>
                                      <br />
                                      {benefit
                                        ? benefit.insurance_compensation
                                        : ""}
                                    </div>
                                    <br />
                                  </div>
                                </div>
                              </Col>
                            </React.Fragment>
                          );
                        }

                        return <React.Fragment></React.Fragment>;
                      })}
                    </Row>

                    {this.genenerateHealthInsuranceSubBenefits(
                      outpatientCareBenefits,
                      "outpatient_care"
                    )}
                  </Panel>

                  <Panel
                    header="Thai sản"
                    key="3"
                    className="site-collapse-custom-panel"
                  >
                    <Row
                      gutter={[16, 16]}
                      css={{
                        borderBottomWidth: "1px",
                        borderTopWidth: "1px",
                      }}
                    >
                      <Col
                        span={4}
                        css={{
                          borderRightWidth: "1px",
                          borderLeftWidth: "1px",
                        }}
                      >
                        <div
                          css={{
                            width: "100%",
                            float: "left",
                            marginRight: "10px",
                            paddingTop: 6,
                            paddingRight: 6,
                            paddingLeft: 6,
                            position: "relative",
                            color: config.color.primary,
                          }}
                        >
                          <strong>Thai sản</strong>
                        </div>
                      </Col>
                      {this.state.selected_compare_items.map((item, index) => {
                        if (item.benefits.length > 0) {
                          let benefit;
                          for (let i = 0; i < item.benefits.length; i++) {
                            if ("pregnancy_care" === item.benefits[i].type) {
                              benefit = item.benefits[i];
                            }
                          }

                          return (
                            <React.Fragment>
                              <Col span={5} css={{ borderRightWidth: "1px" }}>
                                <div
                                  className={"text-left"}
                                  css={{
                                    float: "left",
                                    marginRight: "10px",
                                    color: "#333",
                                    paddingTop: 6,
                                    paddingRight: 20,
                                    paddingLeft: 20,
                                    position: "relative",
                                    fontSize: 12,
                                  }}
                                  onClick={() => {}}
                                >
                                  <div className="text-gray-700">
                                    <div>
                                      <strong>Quyền lợi bảo hiểm:</strong>
                                      <br />
                                      {benefit
                                        ? benefit.insurance_compensation
                                        : ""}
                                    </div>
                                    <br />
                                  </div>
                                </div>
                              </Col>
                            </React.Fragment>
                          );
                        }

                        return <React.Fragment></React.Fragment>;
                      })}
                    </Row>

                    {this.genenerateHealthInsuranceSubBenefits(
                      pregnancyCareBenefits,
                      "pregnancy_care"
                    )}
                  </Panel>

                  <Panel
                    header="Nha khoa"
                    key="3"
                    className="site-collapse-custom-panel"
                  >
                    <Row
                      gutter={[16, 16]}
                      css={{
                        borderBottomWidth: "1px",
                        borderTopWidth: "1px",
                      }}
                    >
                      <Col
                        span={4}
                        css={{
                          borderRightWidth: "1px",
                          borderLeftWidth: "1px",
                        }}
                      >
                        <div
                          css={{
                            width: "100%",
                            float: "left",
                            marginRight: "10px",
                            paddingTop: 6,
                            paddingRight: 6,
                            paddingLeft: 6,
                            position: "relative",
                            color: config.color.primary,
                          }}
                        >
                          <strong>Nha khoa</strong>
                        </div>
                      </Col>
                      {this.state.selected_compare_items.map((item, index) => {
                        if (item.benefits.length > 0) {
                          let benefit;
                          for (let i = 0; i < item.benefits.length; i++) {
                            if ("dentist_care" === item.benefits[i].type) {
                              benefit = item.benefits[i];
                            }
                          }

                          return (
                            <React.Fragment>
                              <Col span={5} css={{ borderRightWidth: "1px" }}>
                                <div
                                  className={"text-left"}
                                  css={{
                                    float: "left",
                                    marginRight: "10px",
                                    color: "#333",
                                    paddingTop: 6,
                                    paddingRight: 20,
                                    paddingLeft: 20,
                                    position: "relative",
                                    fontSize: 12,
                                  }}
                                  onClick={() => {}}
                                >
                                  <div className="text-gray-700">
                                    <div>
                                      <strong>Quyền lợi bảo hiểm:</strong>
                                      <br />
                                      {benefit
                                        ? benefit.insurance_compensation
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </React.Fragment>
                          );
                        }

                        return <React.Fragment></React.Fragment>;
                      })}
                    </Row>

                    {this.genenerateHealthInsuranceSubBenefits(
                      dentistCareBenefits,
                      "dentist_care"
                    )}
                  </Panel>

                  <Panel
                    header="Khác"
                    key="3"
                    className="site-collapse-custom-panel"
                  >
                    <Row
                      gutter={[16, 16]}
                      css={{
                        borderBottomWidth: "1px",
                        borderTopWidth: "1px",
                      }}
                    >
                      <Col
                        span={4}
                        css={{
                          borderRightWidth: "1px",
                          borderLeftWidth: "1px",
                        }}
                      >
                        <div
                          css={{
                            width: "100%",
                            float: "left",
                            marginRight: "10px",
                            paddingTop: 6,
                            paddingRight: 6,
                            paddingLeft: 6,
                            position: "relative",
                            color: config.color.primary,
                          }}
                        >
                          <strong>Khác</strong>
                        </div>
                      </Col>
                      {this.state.selected_compare_items.map((item, index) => {
                        if (item.benefits.length > 0) {
                          let benefit;
                          for (let i = 0; i < item.benefits.length; i++) {
                            if ("other" === item.benefits[i].type) {
                              benefit = item.benefits[i];
                            }
                          }

                          return (
                            <React.Fragment>
                              <Col span={5} css={{ borderRightWidth: "1px" }}>
                                <div
                                  className={"text-left"}
                                  css={{
                                    float: "left",
                                    marginRight: "10px",
                                    color: "#333",
                                    paddingTop: 6,
                                    paddingRight: 20,
                                    paddingLeft: 20,
                                    position: "relative",
                                    fontSize: 12,
                                  }}
                                  onClick={() => {}}
                                >
                                  <div className="text-gray-700"></div>
                                </div>
                              </Col>
                            </React.Fragment>
                          );
                        }

                        return <React.Fragment></React.Fragment>;
                      })}
                    </Row>

                    {this.genenerateHealthInsuranceSubBenefits(
                      otherBenefits,
                      "other"
                    )}
                  </Panel>
                </Collapse>
              </div>
            </div>
          </Content>
        </div>
        <Affix offsetBottom={-10}>
          <div
            css={{
              backgroundColor: "#333",
            }}
          >
            <Content
              className="site-layout"
              css={{
                padding: "0 50px",
                color: "#fff",
              }}
            >
              <div className="site-layout-background" css={{ minHeight: 60 }}>
                <div
                  className={"container mx-auto  "}
                  css={{
                    paddingTop: 4,
                  }}
                >
                  <Row gutter={[16, 16]}>
                    <Col flex={9}></Col>

                    <Col col={1}></Col>

                    <Col col={1}>
                      <button
                        className="rounded-md bg-white transition duration-150
              ease-in-out focus:outline-none rounded text-gray-800
              border-gray-300 px-2 py-1 text-sm"
                        css={{
                          backgroundColor: "#8a8a8a",
                          color: "#fff",
                          margin: "10px 0",
                          paddingRight: 10,
                          paddingLeft: 10,
                        }}
                        onClick={() => {
                          this.setState({ show_compare_table: false });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-square-x"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#fff"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          css={{ float: "left", marginRight: 10 }}
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <rect x="4" y="4" width="16" height="16" rx="2" />
                          <path d="M10 10l4 4m0 -4l-4 4" />
                        </svg>

                        <strong>Ẩn bảng so sánh</strong>
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Content>
          </div>
        </Affix>
      </React.Fragment>
    );
  };

  showBottomCompareItems = () => {
    return (
      <Affix offsetBottom={-10}>
        <div
          css={{
            backgroundColor: "#333",
          }}
        >
          <Content
            className="site-layout"
            css={{
              padding: "0 50px",
              color: "#fff",
            }}
          >
            <div className="site-layout-background" css={{ minHeight: 60 }}>
              <div
                className={"container mx-auto  "}
                css={{
                  paddingTop: 4,
                }}
              >
                <Row gutter={[16, 16]}>
                  <Col flex={9}>
                    {this.state.selected_compare_items.map((val, index) => {
                      return (
                        <React.Fragment>
                          <div
                            className={"rounded-md text-center"}
                            css={{
                              height: 64,
                              width: 160,
                              background: "#dadada",
                              float: "left",
                              margin: "10px 0",
                              marginRight: "10px",
                              color: "#333",
                              paddingTop: 6,
                              paddingRight: 6,
                              paddingLeft: 6,
                              position: "relative",
                            }}
                            onClick={() => {
                              this.removeCompareItem(val);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-x"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              strokeWidth="2.5"
                              stroke="#9e9e9e"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              css={{
                                position: "absolute",
                                top: 4,
                                right: 4,
                              }}
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <line x1="18" y1="6" x2="6" y2="18" />
                              <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                            <strong>
                              {this.state.show_life_insurance_products ? (
                                val.company.name
                              ) : (
                                <React.Fragment>
                                  {checkNested(
                                    val,
                                    "health_insurance_product",
                                    "company",
                                    "name"
                                  )
                                    ? val.health_insurance_product.company.name
                                    : ""}
                                </React.Fragment>
                              )}
                            </strong>
                            <br />
                            <div css={{ fontSize: 11 }}>{val.name}</div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </Col>

                  <Col col={1}>
                    <button
                      className="
                  w-full rounded-md bg-white transition duration-150
                  ease-in-out focus:outline-none
                  rounded text-gray-800 border-gray-300
                  px-2 py-1 text-sm border-2"
                      css={{
                        marginRight: 0,
                        // padding: "6px 20px",
                        borderColor: "#00b84b",
                        backgroundColor: "#00b84b",
                        color: "#fff",
                        float: "right",
                        margin: "10px 0",
                        paddingRight: 10,
                        paddingLeft: 10,
                      }}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        this.setState({ show_compare_table: true });
                      }}
                    >
                      <strong>Xem so sánh</strong>
                    </button>
                  </Col>

                  <Col col={1}>
                    <button
                      className="rounded-md bg-white transition duration-150
              ease-in-out focus:outline-none rounded text-gray-800
              border-gray-300 px-2 py-1 text-sm"
                      css={{
                        backgroundColor: "#8a8a8a",
                        color: "#fff",
                        margin: "10px 0",
                        paddingRight: 10,
                        paddingLeft: 10,
                      }}
                      onClick={() => {
                        this.setState({
                          selected_compare_items: [],
                        });
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-square-x"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#fff"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        css={{ float: "left", marginRight: 10 }}
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x="4" y="4" width="16" height="16" rx="2" />
                        <path d="M10 10l4 4m0 -4l-4 4" />
                      </svg>

                      <strong>Bỏ so sánh</strong>
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </Content>
        </div>
      </Affix>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Layout css={{ backgroundColor: "#fff" }}>
          {!this.state.show_compare_table ? (
            <React.Fragment>
              <LayoutHeader location={this.props.location} />

              <Content
                className="site-layout"
                css={{
                  padding: "0 50px",
                  marginTop: 64,
                  backgroundColor: "#fafafa",
                }}
              >
                <div css={{ paddingTop: 30 }}>
                  <StepHeader currentStep={this.state.current_step - 1} />
                </div>

                <React.Fragment>
                  {this.state.current_step === 1 ? this.showStep1() : ""}
                  {this.state.current_step === 2 ? this.showStep2() : ""}
                  {this.state.current_step === 3 &&
                  this.state.show_life_insurance_products
                    ? this.showStep3LifeInsurance()
                    : ""}
                  {this.state.current_step === 3 &&
                  !this.state.show_life_insurance_products
                    ? this.showStep3HealthInsurance()
                    : ""}
                  {this.state.current_step === 4 ? this.showStep4() : ""}
                </React.Fragment>

                <div css={{ height: 300 }} />
              </Content>

              <React.Fragment>
                {this.state.selected_compare_items.length > 0
                  ? this.showBottomCompareItems()
                  : ""}
              </React.Fragment>

              <LayoutFooter />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {this.state.show_life_insurance_products
                ? this.showCompareTableLifeInsurance()
                : ""}
              {!this.state.show_life_insurance_products
                ? this.showCompareTableHealthInsurance()
                : ""}
            </React.Fragment>
          )}
        </Layout>
      </React.Fragment>
    );
  }
}
