/* eslint-disable no-unused-vars */
import XXH from "xxhashjs";
import randomstring from "randomstring";
import _ from "lodash";
let xxsalt = 0xabcd;
const hostname = window && window.location && window.location.hostname;
const prefix = "[BHV]";
const debug = hostname === "localhost" ? true : false;

let backendHost;

let localPort = ":8000";
let localServer = "http://localhost" + localPort;

if (
  hostname === "beta.baohiemviet.com" ||
  hostname === "baohiemviet.com" ||
  hostname === "baohiemviet.com.vn" ||
  hostname.includes("baohiemviet.com")
) {
  backendHost = "https://cms.baohiemviet.com";
} else {
  backendHost = "https://cms.baohiemviet.com";
  // backendHost = process.env.REACT_APP_BACKEND_HOST || localServer;
}

let config = {
  debug: hostname === "localhost" ? true : false,
  api: {
    get_benefit_types: `${backendHost}/api/types`,
    get_footer: `${backendHost}/api/footer`,
    get_faqs: `${backendHost}/api/faqs/`,
    get_insurance_companies: `${backendHost}/api/insurance_companies/`,
    get_insurance_plans: `${backendHost}/api/insurance_plans/`,
    get_insurance_tags: `${backendHost}/api/insurance_tags/`,
    get_life_insurance_products: `${backendHost}/api/insurance_products/life`,
    get_health_insurance_products: `${backendHost}/api/insurance_products/health_package`,
    get_insurance_products_by_tag: `${backendHost}/api/insurance_products/tag/`,
    get_insurance_products_by_tags: `${backendHost}/api/insurance_products/tags`,
    get_insurance_product_by_slug: `${backendHost}/api/insurance_products/slug/`,
    get_life_insurance_product: `${backendHost}/api/insurance_products/life/`,
    get_health_insurance_product: `${backendHost}/api/insurance_products/health/`,
    post_customer_plan: `${backendHost}/api/customer_plan`,
    post_consultation: `${backendHost}/api/consultation`,
    post_review_request: `${backendHost}/api/review_request`,
    post_review_request_image: `${backendHost}/api/review_request_image`,
    post_claim_request: `${backendHost}/api/claim_request`,
    post_claim_request_image: `${backendHost}/api/claim_request_image`,
  },
  wiki_domain: "https://wiki.baohiemviet.com.vn",
  backend_host: backendHost,
  app_hostname: hostname,
  root_path: "/#/",
  color: {
    primary: "#f76420",
  },
  max_plan: 1,
  menu: [
    {
      key: "home",
      href: "/#/",
      name: "Trang chủ",
    },
    {
      key: "so-sanh",
      href: "/#/so-sanh",
      name: "So sánh sản phẩm",
    },

    {
      key: "https://wiki.baohiemviet.com/",
      href: "https://wiki.baohiemviet.com/",
      name: "Kiến thức bảo hiểm",
    },
    {
      key: "gioi-thieu",
      href: "/#/gioi-thieu",
      name: "Về chúng tôi",
    },
    {
      key: "lien-he",
      href: "/#/lien-he",
      name: "Liên hệ",
    },
    {
      name: "Dịch vụ",
      subs: [
        {
          key: "boi-thuong-bao-hiem",
          href: "/#/boi-thuong-bao-hiem",
          name: "Bồi thường bảo hiểm",
        },
        {
          key: "danh-gia-hop-dong",
          href: "/#/danh-gia-hop-dong",
          name: "Đánh giá hợp đồng bảo hiểm",
        },
      ],
    },
  ],
  currency_locale: {
    style: "currency",
    currency: "VND",
  },
};

export default config;
