/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";
import { message } from "antd";
import { Layout, Menu, Breadcrumb } from "antd";
import { Affix, Button } from "antd";
import { Row, Col } from "antd";
import { chunkArray } from "common/array";
import { getInsurancePlans, getBenefitTypes } from "api/api";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { css, jsx } from "@emotion/react";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import logo from "assets/images/logo.png";
import LayoutHeader from "components/Header";
import LayoutFooter from "components/Footer";
import HomeFAQ from "components/HomeFAQ";
import HomeNews from "components/HomeNews";
import bg1 from "assets/images/bg1.png";
import shield from "assets/images/shield.png";
import priceTags from "assets/images/price-tags.png";
const axios = require("axios");
const { SubMenu } = Menu;
const { Content } = Layout;
export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      select_ordering_score: "-score",
      select_insurance_start_options: "",
      select_insurance_tag: 0,
      selected_insurance_plans: [],
      selected_insurance_answers: [],
      selected_insurance_tags: [7],
      accumulate_answers: [],
      no_accumulate_answers: [],
      insurance_plans: [],
      insurance_tags: [],
      insurance_products: [],
      all_answers: [],
      all_answer_ids: [],
    };
    window.insurance_products = {};

    log(this.props.location);
  }

  componentDidMount() {
    let $this = this;
    window.scrollTo(0, 0);
    getInsurancePlans().then(function (response) {
      $this.setState({ insurance_plans: response });
    });

    getBenefitTypes();
  }

  handleClick = (e) => {
    console.log("click ", e);
    this.setState({ current: e.key });
  };

  handlePlanSelect = (planId) => {
    let selected_insurance_plans = this.state.selected_insurance_plans;

    if (!selected_insurance_plans.includes(planId)) {
      if (config.max_plan - 1 === selected_insurance_plans.length) {
        selected_insurance_plans.push(planId);
        this.setState(
          {
            selected_insurance_plans: selected_insurance_plans,
          },
          () => {
            log(this.state);
          }
        );
      } else {
        message.info(`Vui lòng chỉ chọn ${config.max_plan} dự định`);
      }
    } else {
      selected_insurance_plans.removeIfExists(planId);
      this.setState(
        {
          selected_insurance_plans: selected_insurance_plans,
        },
        () => {
          log(this.state);
        }
      );
    }
  };

  getButtonInsurancePlanStyle = (planId) => {
    let style = {};
    if (this.state.selected_insurance_plans.includes(planId)) {
      style = {
        marginRight: 20,
        borderColor: "#f76420",
        backgroundColor: "#f76420",
        color: "#fff",
      };
    } else {
      style = {
        marginRight: 20,
        borderColor: "#f76420",
      };
    }

    return style;
  };

  generateInsurancePlanButtons = () => {
    let btnInsurancePlanClass = `
    rounded-3xl my-2 bg-white
    transition duration-150
    ease-in-out focus:outline-none
    rounded text-gray-800 border-gray-300
    px-8 py-3 text-sm leading-6  border-2`;

    return this.state.insurance_plans.length > 0 ? (
      <React.Fragment>
        {chunkArray(this.state.insurance_plans, 3).map((arr, index) => {
          return (
            <React.Fragment key={index}>
              {arr.map((val, vindex) => {
                return (
                  <React.Fragment key={val.id}>
                    <button
                      className={btnInsurancePlanClass}
                      css={this.getButtonInsurancePlanStyle(val.id)}
                      onClick={() => {
                        this.handlePlanSelect(val.id);
                      }}
                    >
                      {val.name}
                    </button>
                    {vindex === 2 ? <br /> : ""}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    ) : (
      ""
    );
  };

  render() {
    return (
      <React.Fragment>
        <Layout css={{ backgroundColor: "#fff" }}>
          <LayoutHeader location={this.props.location} />

          <Content
            className="site-layout"
            css={{
              padding: "0 50px",
              marginTop: 64,
              backgroundColor: "#fafafa",
            }}
          >
            <div
              className="site-layout-background"
              css={{ padding: 24, minHeight: 380 }}
            >
              <div
                className={
                  "container mx-auto px-4 bg-no-repeat bg-right-bottom"
                }
                css={{
                  paddingTop: 80,
                  paddingBottom: 160,
                  backgroundImage: `url(${bg1})`,
                }}
              >
                <div className={"text-4xl font-bold text-gray-700"}>
                  Tìm kiếm. So sánh. Chọn lựa!
                </div>
                <div className={"text-2xl font-medium text-gray-500"}>
                  Mời chọn nhu cầu để tìm ra sản phẩm bảo hiểm phù hợp nhất cho
                  bạn.
                </div>

                <div css={{ paddingTop: 20 }}>
                  {this.generateInsurancePlanButtons()}
                </div>
                <div
                  className={"text-sm font-medium text-gray-500"}
                  css={{ paddingTop: 20 }}
                >
                  * Xin vui lòng chọn {config.max_plan} nhu cầu
                </div>
                <div css={{ paddingTop: 20 }}>
                  <button
                    className="rounded-3xl my-2 bg-white transition duration-150 ease-in-out focus:outline-none
                    rounded text-gray-800 border-gray-300 px-8 py-3 text-xl leading-6  border-2"
                    css={{
                      marginRight: 20,
                      padding: "10px 50px",
                      borderColor: "#f76420",
                      backgroundColor: "#f76420",
                      color: "#fff",
                    }}
                    onClick={() => {
                      const params = new URLSearchParams({
                        plans: this.state.selected_insurance_plans,
                      });
                      log(params.toString());

                      window.location.href = "/#/so-sanh?" + params.toString();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-search"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2.5"
                      stroke="#ffffff"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      css={{ float: "left", marginRight: 10 }}
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="10" cy="10" r="7" />
                      <line x1="21" y1="21" x2="15" y2="15" />
                    </svg>
                    <strong>Bắt đầu</strong>
                  </button>
                </div>
              </div>
            </div>

            <div css={{ height: 100, backgroundColor: "#fafafa" }}>
              <div className={"container mx-auto px-4 "}>
                <Row>
                  <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 0 }}>
                    <div
                      css={{ paddingTop: 20, fontSize: 16 }}
                      className="flex"
                    >
                      <div className="flex-initial" css={{ marginRight: 10 }}>
                        <img src={shield} width={48} alt={"icon"} />
                      </div>
                      <div className="flex-grow">
                        <strong css={{ color: "#f76420" }}>
                          Hơn 30 gói bảo hiểm
                        </strong>{" "}
                        từ các công ty bảo hiểm tại Việt Nam.
                      </div>
                    </div>
                  </Col>
                  <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                    <div
                      css={{ paddingTop: 20, fontSize: 16 }}
                      className="flex"
                    >
                      <div className="flex-initial" css={{ marginRight: 10 }}>
                        <img src={shield} width={48} alt={"icon"} />
                      </div>
                      <div className="flex-grow">
                        <strong css={{ color: "#f76420" }}>
                          Dễ dàng so sánh sản phẩm
                        </strong>{" "}
                        với thông tin chi tiết và không thiên vị.{" "}
                      </div>
                    </div>
                  </Col>
                  <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                    <div
                      css={{ paddingTop: 20, fontSize: 16 }}
                      className="flex"
                    >
                      <div className="flex-initial" css={{ marginRight: 10 }}>
                        <img src={shield} width={48} alt={"icon"} />
                      </div>
                      <div className="flex-grow">
                        <strong css={{ color: "#f76420" }}>
                          Cập nhật thông tin ưu đãi mới nhất
                        </strong>{" "}
                        ngay trên kết quả tìm kiếm.{" "}
                      </div>
                    </div>
                  </Col>
                </Row>{" "}
              </div>
            </div>
          </Content>

          <HomeNews />
          <HomeFAQ />
          <LayoutFooter />
        </Layout>
      </React.Fragment>
    );
  }
}
