import React from "react";

export function LoadingBox(props) {
  return (
    <div
      className="border border-yellow-200 shadow
      rounded-md p-4 max-w-sm w-full mx-auto"
      style={{ marginTop: 200 }}
    >
      <div className="animate-pulse flex space-x-4">
        <div className="rounded-full bg-yellow-400 h-12 w-12"></div>
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-yellow-400 rounded w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-yellow-400 rounded"></div>
            <div className="h-4 bg-yellow-400 rounded w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * shortDescription
 * @param {string} text
 */
export function shortDescription(text) {
  return text.split(/\r\n|\r|\n/).map((line, index) => {
    return (
      <React.Fragment key={index}>
        {line}
        <br /> <br />
      </React.Fragment>
    );
  });
}

/**
 * shortDescription
 * @param {string} text
 */
export function shortDescriptionSingleBr(text) {
  return text.split(/\r\n|\r|\n/).map((line, index) => {
    return (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    );
  });
}

export function scrollToRef(ref) {
  // console.log(ref);
  if (ref.current) {
    window.scrollTo(0, ref.current.offsetTop - 100);
  }
}
