export function isNil(val) {
  return val === undefined || val === null;
}

export function isValidJSON(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function isSameDate(dateA, dateB) {
  return dateA.toISOString() === dateB.toISOString();
}

export function isString(val) {
  return typeof val === "string";
}

export function isSymbol(val) {
  return typeof val === "symbol";
}

export function isUpperCase(str) {
  return str === str.toUpperCase();
}

export function redirect(url, asLink = true) {
  return asLink ? (window.location.href = url) : window.location.replace(url);
}

export function runPromisesInSeries(ps) {
  return ps.reduce((p, next) => p.then(next), Promise.resolve());
}

export function checkNested(obj, level, ...rest) {
  if (obj === undefined) return false;
  if (rest.length === 0 && obj.hasOwnProperty(level)) return true;
  return checkNested(obj[level], ...rest);
}

export function makeMobileNumberVN(mobile) {
  let mobile_vn;
  if (mobile.startsWith("+84")) {
    mobile_vn = "0" + mobile.substring(3);
  } else if (mobile.startsWith("84")) {
    mobile_vn = "0" + mobile.substring(2);
  } else if (mobile.startsWith("0") && mobile.length === 10) {
    mobile_vn = mobile;
  }

  return mobile_vn;
}

// const delay = d => new Promise(r => setTimeout(r, d));

// runPromisesInSeries([() => delay(1000), () => delay(2000)]);
