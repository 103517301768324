/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";
import { message } from "antd";
import { Layout, Menu, Breadcrumb } from "antd";
import { Affix, Button } from "antd";
import { Row, Col } from "antd";
import { chunkArray } from "common/array";
import { getInsurancePlans, getBenefitTypes } from "api/api";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { css, jsx } from "@emotion/react";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import logo from "assets/images/logo.png";
import LayoutHeader from "components/Header";
import LayoutFooter from "components/Footer";
const axios = require("axios");
const { SubMenu } = Menu;
const { Content } = Layout;
export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let $this = this;
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Layout css={{ backgroundColor: "#fff" }}>
          <LayoutHeader location={this.props.location} />

          <Content
            className="site-layout"
            css={{
              padding: "0 50px",
              marginTop: 64,
              backgroundColor: "#fafafa",
            }}
          >
            <div
              className="site-layout-background"
              css={{ padding: 24, minHeight: 380 }}
            >
              <div
                className={
                  "container mx-auto px-4 bg-white text-gray-600 rounded-md"
                }
                css={{
                  paddingTop: 80,
                  paddingBottom: 120,
                  paddingLeft: 120,
                  paddingRight: 120,
                  fontSize: 16,
                  marginBottom: 200,
                  borderBottomWidth: 3,
                  borderBottomColor: config.color.primary,
                }}
              >
                <div
                  className="text-2xl"
                  style={{ color: config.color.primary }}
                >
                  <strong>Thông tin liên hệ</strong>
                </div>{" "}
                <br />
                <br />
                <br />
                <strong>
                  Công ty Cổ Phần dịch vụ tư vấn Bảo Hiểm Việt
                </strong>{" "}
                <br />
                Trụ sở : 273 Đội Cấn - Phường Ngọc Hà - Quận Ba Đình - Hà Nội{" "}
                <br />
                Số điện thoại: 024.66.879.899 <br /> Email: dvkh@baohiemviet.com{" "}
                <br />
                Giờ làm việc: Giờ hành chính từ thứ 2 đến thứ 6
                <br />
                <br />
                <br />
                <iframe
                  title={"Map"}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3723.9317224773417!2d105.81881895134852!3d21.035417785926025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135abbf9653d1dd%3A0x353fba10747a7771!2sU%26Bank!5e0!3m2!1sen!2s!4v1613718476671!5m2!1sen!2s"
                  width="100%"
                  height="450"
                  frameborder="0"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  ariaHidden="false"
                  tabindex="0"
                ></iframe>
              </div>
            </div>
          </Content>

          <LayoutFooter />
        </Layout>
      </React.Fragment>
    );
  }
}
