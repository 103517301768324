/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
import XXH from "xxhashjs";
import { Base64 } from "js-base64";
import randomstring from "randomstring";
import config from "config.js";
import log from "common/log.js";
import { v4 as uuidv4 } from "uuid";
import { message } from "antd";
const axios = require("axios");

function timestamp() {
  return Math.floor(Date.now() / 1000);
}

function setCache(k, v) {
  localStorage.setItem(k, v);
  localStorage.setItem(`${k}_timestamp`, timestamp());
}

function getCache(k, duration) {
  if (duration === null) {
    duration = 3600;
  }
  let val = localStorage.getItem(k);
  let valTimestamp = localStorage.getItem(`${k}_timestamp`);

  if (val === null || valTimestamp === null) {
    return null;
  }
  if (val !== null && valTimestamp !== null) {
    if (timestamp() - valTimestamp > duration) {
      return null;
    } else {
      return val;
    }
  }
}

function removeCache(k) {
  localStorage.removeItem(k);
}

export function getCustomerPlan() {
  return localStorage.getItem("customer_plan");
}

export function setCustomerPlan(val) {
  return localStorage.setItem("customer_plan", val);
}

export function getCustomer() {
  return localStorage.getItem("customer");
}

export function setCustomer(val) {
  return localStorage.setItem("customer", val);
}

export function getSessionUUID() {
  let sessionUUID = localStorage.getItem("session_uuid");
  if (sessionUUID === null) {
    sessionUUID = uuidv4();
    localStorage.setItem("session_uuid", sessionUUID);
  }

  return sessionUUID;
}

export function postClaimContract(data) {
  return axios
    .post(config.api.post_claim_request, data)
    .then(function (response) {
      // handle success
      log(response);

      //message.info("Gửi đơn đăng ký thành công.");
      return Promise.resolve(response.data);
    })
    .catch(function (error) {
      // handle error
      log(error);
    })
    .then(function (result) {
      // always executed
      return Promise.resolve(result);
    });
}
export function postReviewContract(data) {
  return axios
    .post(config.api.post_review_request, data)
    .then(function (response) {
      // handle success
      log(response);

      //message.info("Gửi đơn đăng ký thành công.");
      return Promise.resolve(response.data);
    })
    .catch(function (error) {
      // handle error
      log(error);
    })
    .then(function (result) {
      // always executed
      return Promise.resolve(result);
    });
}

export function postCustomer(data) {
  return axios
    .post(config.api.post_consultation, data)
    .then(function (response) {
      // handle success
      log(response);
      setCustomer(response.data.customer);
      message.info("Gửi đơn đăng ký thành công.");
      return Promise.resolve(response.data);
    })
    .catch(function (error) {
      // handle error
      log(error);
    })
    .then(function (result) {
      // always executed
      return Promise.resolve(result);
    });
}

export function postCustomerPlan(data) {
  return axios
    .post(config.api.post_customer_plan, data)
    .then(function (response) {
      // handle success
      log(response);
      setCustomerPlan(response.data.id);
      return Promise.resolve(response.data);
    })
    .catch(function (error) {
      // handle error
      log(error);
    })
    .then(function (result) {
      // always executed
      return Promise.resolve(result);
    });
}
export function putCustomerPlan(planId, data) {
  return axios
    .put(`${config.api.post_customer_plan}/${planId}`, data)
    .then(function (response) {
      // handle success
      log(response);

      return Promise.resolve(response.data);
    })
    .catch(function (error) {
      // handle error
      log(error);
    })
    .then(function (result) {
      // always executed
      return Promise.resolve(result);
    });
}

export function getInsurancePlans() {
  let cache = getCache("insurance_plans");
  if (cache !== null) {
    let data = JSON.parse(cache);
    log("Found insurance_plans cache.", data);
    return Promise.resolve(data);
  }

  return axios
    .get(config.api.get_insurance_plans, {
      params: {},
    })
    .then(function (response) {
      // handle success
      log(response);
      setCache("insurance_plans", JSON.stringify(response.data));
      return Promise.resolve(response.data);
    })
    .catch(function (error) {
      // handle error
      log(error);
    })
    .then(function (result) {
      // always executed
      return Promise.resolve(result);
    });
}

export function getLifeInsuranceProducts(params) {
  return axios
    .get(config.api.get_life_insurance_products, {
      params: params,
    })
    .then(function (response) {
      // handle success
      log(response);
      return Promise.resolve(response.data);
    })
    .catch(function (error) {
      // handle error
      log(error);
    })
    .then(function (result) {
      // always executed
      return Promise.resolve(result);
    });
}

export function getHealthInsuranceProducts(params) {
  return axios
    .get(config.api.get_health_insurance_products, {
      params: params,
    })
    .then(function (response) {
      // handle success
      log(response);
      return Promise.resolve(response.data);
    })
    .catch(function (error) {
      // handle error
      log(error);
    })
    .then(function (result) {
      // always executed
      return Promise.resolve(result);
    });
}

export function getWikiBaoHiemVietNews() {
  let api = `${config.wiki_domain}/api/home`;
  let cache = getCache("wiki_news", 600);
  if (cache !== null) {
    let data = JSON.parse(cache);
    log("Found wiki_news cache.", data);
    return Promise.resolve(data);
  }

  return axios
    .get(api, {})
    .then(function (response) {
      // handle success
      log(response);
      setCache("wiki_news", JSON.stringify(response.data));
      return Promise.resolve(response.data);
    })
    .catch(function (error) {
      // handle error
      log(error);
    })
    .then(function (result) {
      // always executed
      return Promise.resolve(result);
    });
}

export function getFooter() {
  let cache = getCache("footer", 3600);
  if (cache !== null) {
    let data = JSON.parse(cache);
    log("Found footer cache.", data);
    return Promise.resolve(data);
  }

  return axios
    .get(config.api.get_footer, {})
    .then(function (response) {
      // handle success
      log(response);
      setCache("footer", JSON.stringify(response.data));
      return Promise.resolve(response.data);
    })
    .catch(function (error) {
      // handle error
      log(error);
    })
    .then(function (result) {
      // always executed
      return Promise.resolve(result);
    });
}

export function getFAQs() {
  let cache = getCache("faqs", 3600);
  if (cache !== null) {
    let data = JSON.parse(cache);
    log("Found faqs cache.", data);
    return Promise.resolve(data);
  }

  return axios
    .get(config.api.get_faqs, {})
    .then(function (response) {
      // handle success
      log(response);
      setCache("faqs", JSON.stringify(response.data));
      return Promise.resolve(response.data);
    })
    .catch(function (error) {
      // handle error
      log(error);
    })
    .then(function (result) {
      // always executed
      return Promise.resolve(result);
    });
}

export function getCompanies() {
  let cache = getCache("get_insurance_companies", 3600);
  if (cache !== null) {
    let data = JSON.parse(cache);
    log("Found get_insurance_companies cache.", data);
    return Promise.resolve(data);
  }

  return axios
    .get(config.api.get_insurance_companies, {})
    .then(function (response) {
      // handle success
      log(response);
      setCache("get_insurance_companies", JSON.stringify(response.data));
      return Promise.resolve(response.data);
    })
    .catch(function (error) {
      // handle error
      log(error);
    })
    .then(function (result) {
      // always executed
      return Promise.resolve(result);
    });
}

export function getBenefitTypes() {
  let cache = getCache("get_benefit_types", 3600);
  if (cache !== null) {
    let data = JSON.parse(cache);
    log("Found get_benefit_types cache.", data);
    return Promise.resolve(data);
  }

  return axios
    .get(config.api.get_benefit_types, {})
    .then(function (response) {
      // handle success
      log(response);
      setCache("get_benefit_types", JSON.stringify(response.data));
      return Promise.resolve(response.data);
    })
    .catch(function (error) {
      // handle error
      log(error);
    })
    .then(function (result) {
      // always executed
      return Promise.resolve(result);
    });
}

export function getLifeInsuranceProduct(productId) {
  return axios
    .get(`${config.api.get_life_insurance_product}${productId}`)
    .then(function (response) {
      // handle success
      log(response);

      return Promise.resolve(response.data);
    })
    .catch(function (error) {
      // handle error
      log(error);
    })
    .then(function (result) {
      // always executed
      return Promise.resolve(result);
    });
}

export function getHealthInsuranceProduct(productId) {
  return axios
    .get(`${config.api.get_health_insurance_product}${productId}`)
    .then(function (response) {
      // handle success
      log(response);

      return Promise.resolve(response.data);
    })
    .catch(function (error) {
      // handle error
      log(error);
    })
    .then(function (result) {
      // always executed
      return Promise.resolve(result);
    });
}
