/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";
import { Progress } from "antd";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { message } from "antd";
import { Layout, Menu, Breadcrumb } from "antd";
import { Affix, Button } from "antd";
import { Row, Col, Card } from "antd";
import { Steps } from "antd";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
  HeartOutlined,
  SafetyOutlined,
  FileSearchOutlined,
  FolderAddOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import { chunkArray, getRandomItem } from "common/array";
import { checkNested, makeMobileNumberVN } from "common/util";
import { textTruncate } from "common/string";
import {
  getHealthInsuranceProduct,
  postClaimContract,
  postCustomer,
  postReviewContract,
} from "api/api";
import { validEmail, validUrl, valideMobileVN } from "common/validator.js";
import {
  LoadingBox,
  shortDescription,
  shortDescriptionSingleBr,
  scrollToRef,
} from "components/common";
import { getBg } from "common/background";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import logo from "assets/images/logo.png";
import LayoutHeader from "components/Header";
import LayoutFooter from "components/Footer";

const slugify = require("slugify");
const queryString = require("query-string");
const axios = require("axios");
const { Step } = Steps;
const { SubMenu } = Menu;
const { Content } = Layout;
const { Panel } = Collapse;

class FileUploadService {
  constructor() {}

  upload(requestId, file, onUploadProgress) {
    let formData = new FormData();

    formData.append("claim_request", requestId);
    formData.append("image", file);

    return axios.post(config.api.post_claim_request_image, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }
}

export default class ClaimContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      email: "",

      selectedFiles: undefined,
      previewImages: [],
      progressInfos: [],
      message: [],
      imageInfos: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  selectFiles = (event) => {
    let images = [];

    for (let i = 0; i < event.target.files.length; i++) {
      images.push(URL.createObjectURL(event.target.files[i]));
    }

    this.setState({
      progressInfos: [],
      message: [],
      selectedFiles: event.target.files,
      previewImages: images,
    });
  };

  uploadImages = () => {
    const selectedFiles = this.state.selectedFiles;

    let _progressInfos = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
    }

    this.setState(
      {
        progressInfos: _progressInfos,
        message: [],
      },
      () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          this.upload(i, selectedFiles[i]);
        }
      }
    );
  };

  upload = (idx, file) => {
    let _progressInfos = [...this.state.progressInfos];

    new FileUploadService()
      .upload(this.state.requestId, file, (event) => {
        _progressInfos[idx].percentage = Math.round(
          (100 * event.loaded) / event.total
        );
        this.setState({
          progressInfos: _progressInfos,
        });
      })
      .then(() => {
        this.setState((prev) => {
          let nextMessage = [
            ...prev.message,
            "Uploaded the image successfully: " + file.name,
          ];
          return {
            message: nextMessage,
          };
        });

        // return UploadService.getFiles();
      })
      .then((files) => {
        // this.setState({
        //   imageInfos: files.data,
        // });
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        this.setState((prev) => {
          let nextMessage = [
            ...prev.message,
            "Could not upload the image: " + file.name,
          ];
          return {
            progressInfos: _progressInfos,
            message: nextMessage,
          };
        });
      });
  };

  submitCustomer = () => {
    let data = {
      customer: {},
      claim_request: {
        notes: "",
      },
    };

    let $this = this;
    let hasAlerted = false;
    let ok = true;
    let formData = {
      full_name: $this.state.name,
      phone: $this.state.phone,
      email: $this.state.email,
    };

    // Check name
    if (formData.name === "") {
      ok = false;
    }

    // Check mail
    if (formData.email === "") {
      ok = false;
    } else {
      if (validEmail(formData.email) === false) {
        if (!hasAlerted) {
          message.info("Email của bạn không đúng định dạng!");
          hasAlerted = true;
        }

        ok = false;
      }
    }

    // Check phone
    if (formData.phone === "") {
      ok = false;
    } else {
      let is_mobile_ok = false;
      formData.phone = makeMobileNumberVN(formData.phone);
      if (formData.phone !== "") {
        if (valideMobileVN(formData.phone) === false) {
          if (!hasAlerted) {
            hasAlerted = true;
            message.info("Số điện thoại của bạn không đúng định dạng!");
          }
        } else {
          is_mobile_ok = true;
        }
      } else {
        if (!hasAlerted) {
          hasAlerted = true;
          message.info("Bạn chưa điền số điện thoại!");
        }
      }

      if (is_mobile_ok === false) {
        ok = false;
      }
    }

    if (ok === true) {
      data["customer"] = formData;
      postClaimContract(data).then((response) => {
        $this.setState(
          {
            requestId: response.id,
          },
          () => {
            $this.uploadImages();
          }
        );
      });
    } else {
      if (!hasAlerted) {
        message.info("Mời bạn nhập đầy đủ thông tin trên form.");
      }
    }
  };

  render() {
    const {
      selectedFiles,
      previewImages,
      progressInfos,
      message,
      imageInfos,
    } = this.state;

    return (
      <React.Fragment>
        <Layout css={{ backgroundColor: "#fff" }}>
          <LayoutHeader location={this.props.location} />

          <Content
            className="site-layout"
            css={{
              padding: " 50px",
              backgroundColor: "#fafafa",
            }}
          >
            <div css={{ paddingTop: 80, paddingBottom: 80 }}>
              <div
                className={"shadow-sm rounded-md"}
                css={{
                  backgroundColor: "#fff",
                  marginTop: 50,
                  width: 820,
                  margin: "auto",
                }}
              >
                <div className="xl:w-full border-b border-gray-300 py-6">
                  <div className="flex items-center w-11/12 mx-auto">
                    <div className="text-lg text-gray-800 font-bold">
                      Đăng ký hỗ trợ bồi thường bảo hiểm
                    </div>
                    <div className="ml-2 cursor-pointer text-gray-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={16}
                        height={16}
                      >
                        <path
                          className="heroicon-ui"
                          d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm0-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="w-11/12 mx-auto">
                  <div className="container mx-auto">
                    <div
                      className="my-6 mx-auto xl:w-full xl:mx-0"
                      style={{ marginTop: 20, marginBottom: 30 }}
                    >
                      <div className="xl:w-full lg:w-full md:w-full flex flex-col mb-4">
                        <label
                          htmlFor="email2"
                          className="pb-2 text-sm font-bold text-gray-800"
                        >
                          Số điện thoại
                        </label>
                        <div className="relative">
                          <div className="absolute text-gray-600 flex items-center px-4 border-r h-full">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                              <path d="M15 7a2 2 0 0 1 2 2" />
                              <path d="M15 3a6 6 0 0 1 6 6" />
                            </svg>
                          </div>
                          <input
                            value={this.state.phone}
                            onChange={(e) => {
                              this.setState({ phone: e.target.value });
                            }}
                            id="phone"
                            name="phone"
                            required
                            className="w-full text-gray-800 focus:outline-none
                        focus:border focus:border-indigo-700 font-normal py-2 flex
                        items-center pl-16 text-sm rounded border"
                            placeholder="Mời bạn nhập SĐT"
                          />
                        </div>
                      </div>

                      <div className="xl:w-full lg:w-full md:w-full flex flex-col mb-4">
                        <label
                          htmlFor="FirstName"
                          className="pb-2 text-sm font-bold text-gray-800"
                        >
                          Họ tên
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          required
                          id="FirstName"
                          className="border border-gray-300 pl-3 py-2 shadow-sm rounded text-sm
                       focus:outline-none focus:border-indigo-700 text-gray-800"
                          placeholder="Mời bạn nhập họ tên"
                          value={this.state.name}
                          onChange={(e) => {
                            this.setState({ name: e.target.value });
                          }}
                        />
                      </div>

                      <div className="xl:w-full lg:w-full md:w-full flex flex-col mb-4">
                        <label
                          htmlFor="email2"
                          className="pb-2 text-sm font-bold text-gray-800"
                        >
                          Email
                        </label>
                        <div className="relative">
                          <div className="absolute text-gray-600 flex items-center px-4 border-r h-full">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-mail"
                              width={20}
                              height={20}
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" />
                              <rect x={3} y={5} width={18} height={14} rx={2} />
                              <polyline points="3 7 12 13 21 7" />
                            </svg>
                          </div>
                          <input
                            value={this.state.email}
                            onChange={(e) => {
                              this.setState({ email: e.target.value });
                            }}
                            id="email2"
                            name="email"
                            required
                            className="w-full text-gray-800 focus:outline-none focus:border
                        focus:border-indigo-700 font-normal py-2 flex items-center pl-16 text-sm rounded border"
                            placeholder="example@gmail.com"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div css={{ padding: 30 }}>
                  <div className="row">
                    <div className="col-8">
                      Bạn vui lòng upload hợp đồng bảo hiểm của bạn (ảnh hoặc
                      file pdf) <br />
                      <br />
                      <label className="btn btn-default p-0">
                        <input
                          type="file"
                          multiple
                          accept="image/*"
                          onChange={this.selectFiles}
                        />
                      </label>
                    </div>
                  </div>

                  {progressInfos &&
                    progressInfos.map((progressInfo, index) => (
                      <div className="mb-2" key={index}>
                        <span>{progressInfo.fileName}</span>
                        <Progress percent={progressInfo.percentage} />
                        {/* <div className="progress">
                          <div
                            className="progress-bar progress-bar-info"
                            role="progressbar"
                            aria-valuenow={progressInfo.percentage}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progressInfo.percentage + "%" }}
                          >
                            {progressInfo.percentage}%
                          </div>
                        </div> */}
                      </div>
                    ))}

                  {previewImages && (
                    <div>
                      {previewImages.map((img, i) => {
                        return (
                          <img
                            className="preview"
                            src={img}
                            alt={"image-" + i}
                            key={i}
                          />
                        );
                      })}
                    </div>
                  )}

                  {message.length > 0 && (
                    <div className="alert alert-secondary" role="alert">
                      <ul>
                        {message.map((item, i) => {
                          return <li key={i}>{item}</li>;
                        })}
                      </ul>
                    </div>
                  )}
                </div>

                <div className="w-full h-20 bg-gray-100 rounded-b">
                  <button
                    className="rounded-md my-4 bg-white transition duration-150
                  ease-in-out focus:outline-none rounded text-gray-800
                  border-gray-300 px-8 py-2 text-xl leading-6  border-2"
                    css={{
                      float: "right",
                      marginRight: 20,
                      // padding: "10px 50px",
                      borderColor: "#f76420",
                      backgroundColor: "#f76420",
                      color: "#fff",
                    }}
                    disabled={!selectedFiles}
                    onClick={() => {
                      this.submitCustomer();
                    }}
                  >
                    <strong>Gửi tư vấn</strong>
                  </button>
                </div>
              </div>
            </div>
          </Content>

          <LayoutFooter />
        </Layout>
      </React.Fragment>
    );
  }
}
