/** @jsxRuntime classic */
/** @jsx jsx */
/* eslint-disable no-unused-vars */
/* eslint-disable no-extend-native */
import logo from "./logo.svg";
import "./assets/main.css";
import "./App.less";
import { v4 as uuidv4 } from "uuid";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { getSessionUUID } from "api/api";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Link,
  Switch,
} from "react-router-dom";
import { css, jsx } from "@emotion/react";
import Home from "controllers/Home";
import Compare from "controllers/Compare";
import AboutUs from "controllers/AboutUs";
import Contact from "controllers/Contact";
import LifeProduct from "controllers/LifeProduct";
import HealthProduct from "controllers/HealthProduct";
import ClaimContract from "controllers/ClaimContract";
import ReviewContract from "controllers/ReviewContract";
const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        return renderMergedProps(component, routeProps, rest);
      }}
    />
  );
};

String.prototype.capitalize = function () {
  return this.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

// check if an element exists in array using a comparer function
// comparer : function(currentElement)
Array.prototype.inArray = function (comparer) {
  for (var i = 0; i < this.length; i++) {
    if (comparer(this[i])) return true;
  }
  return false;
};

// adds an element to the array if it does not already exist using a comparer
// function
Array.prototype.pushIfNotExist = function (element, comparer) {
  if (!this.inArray(comparer)) {
    this.push(element);
  }
};

// adds an element to the array if it does not already exist using a comparer
// function
Array.prototype.removeIfExists = function (element) {
  const index = this.indexOf(element);
  if (index > -1) {
    this.splice(index, 1);
  }
};

export default class App extends Component {
  componentDidMount() {
    getSessionUUID();

    if (
      [
        "https://beta.baohiemviet.com",
        "https://www.baohiemviet.com",
        "https://www.baohiemviet.com.vn",
        "https://baohiemviet.com",
        "https://baohiemviet.com.vn",
        "http://localhost:3000",
      ].includes(window.location.origin)
    ) {
      let reg = window.location.href.match(
        /(https|http):\/\/(baohiemviet.com|www.baohiemviet.com|baohiemviet.com.vn|www.baohiemviet.com.vn|beta.baohiemviet.com|localhost:3000)\/((?!#).)/g
      );

      if (reg != null) {
        let newUrl = window.location.href.replace(
          window.location.origin,
          `${window.location.origin}/#`
        );

        if (newUrl.endsWith("#/")) {
          newUrl = newUrl.slice(0, -2);
        }
        window.location.href = newUrl;
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        <HashRouter>
          <Switch>
            <PropsRoute
              exact
              key="home"
              path="/"
              name="home"
              component={Home}
            />
            <PropsRoute
              exact
              key="sosanh"
              path="/so-sanh"
              name="sosanh"
              component={Compare}
            />
            <PropsRoute
              exact
              key="boi-thuong-bao-hiem"
              path="/boi-thuong-bao-hiem"
              name="boi-thuong-bao-hiem"
              component={ClaimContract}
            />
            <PropsRoute
              exact
              key="danh-gia-hop-dong"
              path="/danh-gia-hop-dong"
              name="danh-gia-hop-dong"
              component={ReviewContract}
            />
            <PropsRoute
              exact
              key="gioithieu"
              path="/gioi-thieu"
              name="gioithieu"
              component={AboutUs}
            />
            <PropsRoute
              exact
              key="lienhe"
              path="/lien-he"
              name="lienhe"
              component={Contact}
            />
            <PropsRoute
              exact
              key="bao-hiem-nhan-tho"
              path="/bao-hiem-nhan-tho/:id/:company/:slug"
              name="bao-hiem-nhan-tho"
              component={LifeProduct}
            />
            <PropsRoute
              exact
              key="bao-hiem-suc-khoe"
              path="/bao-hiem-suc-khoe/:id/:company/:slug"
              name="bao-hiem-suc-khoe"
              component={HealthProduct}
            />
          </Switch>
        </HashRouter>
      </React.Fragment>
    );
  }
}

PropsRoute.propTypes = {
  component: PropTypes.func,
};
